import React from "react";
import { SplitScreen } from "../Template/ScreenRouter";
import { useSelector } from "react-redux";
import {getSelectedProduct, getSelectedProductId} from "../../reducers/products/selectors";
import {Product, Products} from "../Products";
import { PurchaseForm } from "../Purchase";

function PurchaseDataScreen() {
  const product = useSelector(getSelectedProduct);
  const products = useSelector(getSelectedProductId);

  if (!products) {
    return <></>;
  }

  return (
    <SplitScreen
        height={true}
      rightScreen={products.length === 1? <Product preview={true} product={product[0]} />: <Products/>}
      leftScreen={<PurchaseForm />}
    />
  );
}

export default PurchaseDataScreen;
