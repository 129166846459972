import React, {useEffect} from "react";
import Screen from "./containers/Template";
import {Provider} from "react-redux";
import store from "./store";

function App() {

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/config/').then(d => d.json()).then(data => {
            document.getElementsByTagName('meta')["description"].content = data['description'];
            document.title = data['title'];
        })
    }, [])

  return (
    <Provider store={store}>
      <Screen />
    </Provider>
  );
}


export default App;
