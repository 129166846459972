import React from 'react'
import styled from "styled-components";
import {useDispatch} from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import {RoundButton, ScreenWrapper} from "../../components/Screens";

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
`

function FakePayment({}: IFakePayment) {
 const dispatch = useDispatch();

 const onClick = (step: string) => () => dispatch(nextStep(step));


 return (
  <Wrapper>
   <RoundButton
     onClick={onClick('paymentFailed')}
     style={{ margin: "30px 10px", padding: "0px 50px", fontWeight: 500 }}
   >
    Failed
   </RoundButton>
   <RoundButton
     onClick={onClick('paymentSuccess')}
     style={{ margin: "30px 10px", padding: "0px 50px", fontWeight: 500 }}
   >
    Success
   </RoundButton>
  </Wrapper>
 );
};

export interface IFakePayment {

}

export default FakePayment