import React from "react";
import StepStatus, { StatusIconType } from "../../components/Steps/StepStatus";
import { Input } from "../../components/Steps";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import { ProductsScreenWrapper, RoundButton } from "../../components/Screens";
import content from "../../content";
import { getOrderSid } from "../../reducers/products/selectors";

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
`;

function PurchasePaymentFailedScreen() {
  const dispatch = useDispatch();
  const orderSid = useSelector(getOrderSid);

  const info = content.getValue("payment_failed_info");

  const onClick = () => {
    fetch(process.env.REACT_APP_API_URL + "/order/sid/" + orderSid)
      .then((results) => results.json())
      .then((data) => {
        if ("paymentToken" in data) {
          window.location.href = `https://sandbox.przelewy24.pl/trnRequest/${data["paymentToken"]}`;
        }
      });
  };

  const Content = (
    <ContentWrapper>
      <RoundButton
        onClick={onClick}
        style={{ fontWeight: 500, marginTop: "100px", padding: "0px 50px" }}
      >
        {content.getValue("payment_failed_button")}
      </RoundButton>
    </ContentWrapper>
  );

  return (
    <ProductsScreenWrapper>
      <StepStatus
        iconType={StatusIconType.Success}
        count={0}
        hide={true}
        Content={Content}
        backStep={"products"}
        backLabel={content.getValue("back_to_shop_button")}
        info={info}
        headerLabel={content.getValue("payment_failed_header")}
        label={content.getValue("payment_failed_subheder")}
      />
    </ProductsScreenWrapper>
  );
}

export default PurchasePaymentFailedScreen;
