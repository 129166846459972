import React from "react";
import styled from "styled-components";

const StyledInput = styled.textarea`
  box-sizing: border-box;
  background-color: white;
  border: ${(props:any) => Boolean(props.error) && Boolean(props.touched)?  '1px solid darkred':'1px solid rgb(230, 230, 230)'};
  width: 100%;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 16px;
  color: rgb(85, 85, 85);
  margin-bottom: 20px;
  &:focus {
    background-color: white;
  }
`;

function TextArea({ placeholder, input, meta, value, ...rest }: IInput) {
    return (
        <StyledInput
            {...rest}
            value={value? Number(value) <= 0? 0:value:value }
            {...input}
            {...meta}
            placeholder={placeholder}
        />
    );
}

export default TextArea;
export interface IInput extends React.HtmlHTMLAttributes<HTMLInputElement> {
    value?: string;
    input?: any;
    meta?: any;
    min?: number
    type?: string
}
