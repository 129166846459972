import React, { useEffect, useState } from "react";
import { IStep } from "../../../containers/Steps";
import { FormControlLabel, Input, RadioGroup } from "../index";
import { IAnswers } from "../../../steps";
import Checkbox from "../Checkbox";
import content from "../../../content";
import { Row } from "../../../containers/Purchase/PurchaseForm";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../reducers/rootReducer";
import { StepsActions } from "../../../reducers/steps/actionsNames";
import { MenuItem, Radio, Select, makeStyles } from "@material-ui/core";
import { getCurrentStepAnswers } from "../../../reducers/steps/selectors";

const useStyles = makeStyles({
  select: {
    border: "1px solid #ffbd00",
    "&:before": {
      border: "unset",
    },
    "&:hover:before": {
      border: "unset",
    },
  },
});
function CustomStep4({ step, onChange, setNext }: ICustomStep9) {
  const [customFilter, setCustomFilter] = useState("");
  const [value, setValue] = useState<any>("");
  const [a, setA] = useState();

  const classes = useStyles();

  const dispatch = useDispatch();

  const setCustom = (v: number) =>
    dispatch({ type: StepsActions.SetCustom, v });
  const setUnits = (v: number) => dispatch({ type: StepsActions.SetUnits, v });
  const setPeriod = (v: number) =>
    dispatch({ type: StepsActions.SetPeriod, v });

  const units = useSelector((state: State) => state.steps.units);
  const period = useSelector((state: State) => state.steps.period);
  const customValue = useSelector((state: State) => state.steps.customValue);
  const stepValue = useSelector(getCurrentStepAnswers);

  useEffect(() => {
    if (stepValue) {
      setValue(stepValue.value);
    }
  }, [stepValue]);

  const changeCustom = (v: number, a: IAnswers) => () => {
    setCustom(v);
    setTimeout(
      () =>
        change(
          a,
          v,
          "",
          false,
          units,
          period
        )({ currentTarget: { value: value } } as any),
      200
    );
    setA(a);
  };

  const changeUnits = (v: any, a: IAnswers) => () => {
    setUnits(v);
    setTimeout(() => {
      change(
        a,
        customValue,
        "",
        false,
        v,
        period
      )({ currentTarget: { value: value } } as any);
    }, 200);
    setA(a);
  };

  const changePeriod = (v: any, a: IAnswers) => () => {
    setPeriod(v);
    setTimeout(
      () =>
        change(
          a,
          customValue,
          "",
          false,
          units,
          v
        )({ currentTarget: { value: value } } as any),
      200
    );
    setA(a);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: StepsActions.RemoveAnswer, step: step.step });
      setValue("");
      setNext("");
    }, 300);
  }, [customValue]);

  useEffect(() => {
    if (a && a.value) {
      change(a, customValue)({ currentTarget: { value: value } } as any);
    }
  }, [a, period, customValue, units]);

  function change(
    answer: IAnswers,
    customValue: number,
    aa = "",
    select = false,
    units2 = undefined,
    period2 = undefined
  ) {
    return function (e: React.SyntheticEvent<HTMLInputElement> | any) {
      let customFilters: any = {};
      const personValues = [50, 100, 145, 190, 230, 270, 305, 340, 370, 400];

      let personValue = 0;
      let literValue = 0;

      if (Number(customValue) === 0) {
        setCustomFilter(
          mapPersonsToRange(Number(aa))
            ? "Średnie zapotrzebowanie dla: " + mapPersonsToRange(Number(aa))
            : ""
        );
        customFilters["Średnie zapotrzebowanie dla:"] = mapPersonsToRange(
          Number(aa)
        );
        customFilters["(Max) Średnie zapotrzebowanie dla:"] = addRange(
          mapPersonsToRange(Number(aa))
        );
        personValue = Number(aa);
        literValue = mapPersonsToLitres(Number(aa));
      }

      if (Number(customValue) === 1) {
        let value = Number(select ? e?.target?.value : e.currentTarget.value);
        setValue(value);
        if (String(units2 || units) === "2") {
          value = value * 1000;
        }

        if (String(period2 || period) === "5") {
          value = value / 2;
        }

        value = value / 90;

        customFilters["Średnie zapotrzebowanie dla:"] = mapLitresToRange(value);

        customFilters["(Max) Średnie zapotrzebowanie dla:"] = addRange(
          mapLitresToRange(value)
        );

        setCustomFilter(
          (isNaN(value) ? "brak" : mapLitresToRange(value))
            ? "Średnie zapotrzebowanie dla: " +
                (isNaN(value) ? "brak" : mapLitresToRange(value))
            : ""
        );

        personValue = mapLitresToPersons(
          personValues.reduce(function (prev, curr) {
            return Math.abs(curr - value) < Math.abs(prev - value)
              ? curr
              : prev;
          })
        );
        literValue = Number(value);
      }

      onChange(
        {
          ...answer,
          content: personValue,
          liters: literValue,
          customFilters,
        } as any,
        select ? e?.target?.value : e.currentTarget.value
      );
    };
  }

  return (
    <>
      {step.answers.map((a) => (
        <>
          {step.customFields && step.customFields.length !== 0 && (
            <>
              <Row key={(a as any).value + "r"} style={{ width: "100%" }}>
                <RadioGroup
                  value={customValue}
                  name={"customValueType"}
                  style={{ width: "100%" }}
                >
                  {step.customFields.slice(0, 2).map((c) => (
                    <FormControlLabel
                      onChange={changeCustom(c.value, a)}
                      key={c.value}
                      value={c.value}
                      control={<Radio />}
                      label={content.getValue(c.content)}
                    ></FormControlLabel>
                  ))}
                </RadioGroup>
              </Row>

              <Row key={(a as any).value + "d"} style={{ width: "100%" }}>
                {String(customValue) === "1" && (
                  <RadioGroup
                    value={units}
                    name={"customValueUnit"}
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {step.customFields.slice(2, 4).map((c) => (
                      <FormControlLabel
                        onChange={changeUnits(c.value, a)}
                        key={c.value}
                        value={c.value}
                        style={{ width: "45%" }}
                        control={<Radio />}
                        label={content.getValue(c.content)}
                      ></FormControlLabel>
                    ))}
                  </RadioGroup>
                )}
              </Row>

              <Row key={(a as any).value + "c"} style={{ width: "100%" }}>
                {String(customValue) === "1" && (
                  <RadioGroup
                    value={period}
                    name={"customValuePeriod"}
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {step.customFields.slice(4, 6).map((c) => (
                      <FormControlLabel
                        onChange={changePeriod(c.value, a)}
                        key={c.value}
                        value={c.value}
                        style={{ width: "45%" }}
                        control={<Radio />}
                        label={content.getValue(c.content)}
                      ></FormControlLabel>
                    ))}
                  </RadioGroup>
                )}
              </Row>
            </>
          )}
          {String(customValue) === "1" && units !== 0 && (
            <Input
              style={{ marginBottom: "5px", marginTop: "10px" }}
              value={(a as any).value}
              onChange={change(a, customValue)}
              key={a.name}
              placeholder={
                a.content +
                (step.customFields
                  ? ` (${content.getValue(
                      step?.customFields[Number(units)]?.content as any
                    )}/${content.getValue(
                      step?.customFields[Number(period)]?.content as any
                    )})`
                  : "")
              }
              type={"number"}
            />
          )}
          {String(customValue) === "0" && (
            <Select
              disableUnderline={true}
              displayEmpty={true}
              className={classes.select}
              style={{
                height: "50px",
                backgroundColor: "white",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
                color: `black`,
                marginTop: "10px",
                marginBottom: `20px`,
                padding: "0px 10px",
              }}
              defaultValue={0}
              onChange={(aa) =>
                change(a, customValue, aa?.target?.value as any, true)(aa)
              }
            >
              <MenuItem value={0} disabled>
                {content.getValue("select_person_count")}
              </MenuItem>
              {Array.from(new Array(10)).map((_, i) => (
                <MenuItem style={{ height: "50px" }} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
              <MenuItem style={{ height: "50px" }} value={11}>
                Powyżej 10
              </MenuItem>
            </Select>

            //   <RadioGroup name={step.step}>
            //   {AnwserArray.map((aa,i) => (
            //     <FormControlLabel
            //       key={aa}
            //       onChange={change(a, customValue, aa)}
            //       value={aa}
            //       control={<Radio />}
            //       label={aa}
            //     >
            //     </FormControlLabel>
            //   ))}
            // </RadioGroup>
          )}

          {process.env.REACT_APP_DEBUG && (
            <div style={{ marginTop: "-5px", marginBottom: "10px" }}>
              {customFilter}
            </div>
          )}
        </>
      ))}
    </>
  );
}

const mapPersonsToLitres = (num: number) => {
  switch (num) {
    case 1:
      return 50;
    case 2:
      return 100;
    case 3:
      return 145;
    case 4:
      return 190;
    case 5:
      return 230;
    case 6:
      return 270;
    case 7:
      return 305;
    case 8:
      return 340;
    case 9:
      return 370;
    case 10:
      return 400;
    default:
      return 400;
  }
};

const mapLitresToPersons = (num: number) => {
  switch (num) {
    case 50:
      return 1;
    case 100:
      return 2;
    case 145:
      return 3;
    case 190:
      return 4;
    case 230:
      return 5;
    case 270:
      return 6;
    case 305:
      return 7;
    case 340:
      return 8;
    case 370:
      return 9;
    case 400:
      return 10;
    default:
      return 10;
  }
};

export const mapLitresToRange = (litres: number) => {
  if (litres >= 0 && litres <= 100) {
    return "od 1 do 3 osób";
  } else if (litres <= 145) {
    return "od 2 do 4 osób";
  } else if (litres <= 190) {
    return "od 3 do 5 osób";
  } else if (litres <= 230) {
    return "od 4 do 6 osób";
  } else if (litres <= 270) {
    return "od 5 do 7 osób";
  } else if (litres <= 340) {
    return "od 6 do 8 osób";
  } else if (litres <= 400) {
    return "od 7 do 9 osób";
  } else {
    return "od 8 do 10 osób";
  }
};

export const addRange = (range) => {
  switch (range) {
    case "od 1 do 3 osób":
      return "od 2 do 4 osób";
    case "od 2 do 4 osób":
      return "od 3 do 5 osób";
    case "od 3 do 5 osób":
      return "od 4 do 6 osób";
    case "od 4 do 6 osób":
      return "od 5 do 7 osób";
    case "od 5 do 7 osób":
      return "od 6 do 8 osób";
    case "od 6 do 8 osób":
      return "od 7 do 9 osób";
    case "od 7 do 9 osób":
      return "od 8 do 10 osóbC";
    default:
      return undefined;
  }
};

const addLitresToValue = (litres: number) => {
  if (litres >= 305) {
    return litres + 35;
  } else if (litres >= 270) {
    return litres + 40;
  } else if (litres >= 230) {
    return litres + 40;
  } else if (litres >= 190) {
    return litres + 45;
  } else if (litres >= 145) {
    return litres + 45;
  } else {
    return 145;
  }
};

const mapPersonsToRange = (persons: number) => {
  switch (persons) {
    case 1:
      return "od 1 do 3 osób";
    case 2:
      return "od 1 do 3 osób";
    case 3:
      return "od 2 do 4 osób";
    case 4:
      return "od 3 do 5 osób";
    case 5:
      return "od 4 do 6 osób";
    case 6:
      return "od 5 do 7 osób";
    case 7:
      return "od 6 do 8 osób";
    case 8:
      return "od 7 do 9 osób";
    case 9:
      return "od 7 do 9 osób";
    case 10:
      return "od 8 do 10 osób";
    case 11:
      return undefined;
    default:
      return "od 8 do 10 osób";
  }
};

export interface ICustomStep9 extends IStep {
  value?: string;
  setNext: any;
}

export default CustomStep4;
