import { State } from "../rootReducer";
import { createSelector } from "reselect";
import { IDelivery } from "./index";
import { IProduct } from "../../products";

export const getProducts = (state: State) => state.products.products;
export const getProductsAll = (state: State) => state.products.allProducts;
export const getProductsFiltered = (state: State) =>
  state.products.filteredProducts;
export const getSelectedProductId = (state: State) =>
  state.products.selectedProduct;
export const getFormValues = (state: State) => state.products.form;
export const getPaymentMethod = (state: State) => state.products.paymentMethod;
export const getOrderId = (state: State) => state.products.orderId;
export const getPaymentDelivery = (state: State) => state.products.delivery;
export const getDeliveryId = (state: State) => state.products.deliveryId;
export const getProductsLoading = (state: State) => state.products.loading;
export const getParam = (store: State) => store.products.param;
export const getCupon = (store: State) => store.products.cupon;
export const getDelivery = createSelector(
  [getPaymentDelivery, getDeliveryId],
  (delivery, id) => delivery.find((d: IDelivery) => Number(d.id) === Number(id))
);
export const getOrderToken = (store: State) => store.products.orderToken;
export const getOrderSid = (store: State) => store.products.orderSid;

export const getSelectedProduct = createSelector(
  [getProductsFiltered, getSelectedProductId],
  (products, s: any) =>
    products
      .filter((p: IProduct) => s.find((pi: any) => pi.id === p.id))
      .map((p) => ({ ...p, count: s.find((si) => si.id === p.id).count }))
);
