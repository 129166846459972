import React from "react";
import { useSelector } from "react-redux";
import {getSelectedProduct, getSelectedProductId} from "../../reducers/products/selectors";
import { SplitScreen } from "../Template/ScreenRouter";
import {Product, Products} from "../Products";
import { PurchaseInfo, PurchasePayment } from "../Purchase";

function PurchaseInfoScreen({}: IPurchaseInfoScreen) {
  const product = useSelector(getSelectedProduct);
  const products = useSelector(getSelectedProductId);

  if (!products) {
    return <></>;
  }

  return (
    <SplitScreen
        height={true}
      rightScreen={products.length === 1? <Product delivery={true} preview={true} product={product[0]} />: <Products delivery={true}/>}
      leftScreen={<PurchaseInfo />}
    />
  );
}

export interface IPurchaseInfoScreen {}

export default PurchaseInfoScreen;
