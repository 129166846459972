import React, {useEffect, useState} from "react";
import styled from "styled-components";
import UnderlineButton from "../Screens/UnderlineButton";
import {ChevronLeft, ChevronRight, HelpOutline, Phone, MailOutline} from "@material-ui/icons";
import nextStep from "../../actions/steps/nextStep";
import {useDispatch, useSelector} from "react-redux";
import content from "../../content";
import previousStep from "../../actions/steps/previousStep";
import {getSelectedProductId} from "../../reducers/products/selectors";
import { ContactDrawer } from "../Screens/InfoDrawer";
import {getCurrentStep, getStepAnswers} from "../../reducers/steps/selectors";
import {useMediaQuery} from "@material-ui/core";

const StepStatusHeaderWrapper = styled.div<{count?: number}>`
  width: 100%;
  align-items: center;
  justify-content: ${({count}) => count? 'start':'center'};
  display: flex;
  
  font-size: 2.0em;
  @media (max-width: 800px) {
      font-size: 1.3em;
  }
  
  font-weight: 600;
  color: black;
  
`;

const Count = styled.div`
  font-weight: 500;
  color: black;
  margin: 0px 10px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 75px;
  left: 75px;
`;

function StepStatusHeader({ hide, count,secondLabel, label, step, backLabel, backStep, repeat = false, cart = false, filter = false, hideAll= false }: IProductsHeader) {
  const dispatch = useDispatch();
  const filterActive = useSelector(getStepAnswers).length === 0
  const current = useSelector(getCurrentStep)
  const onClick = () => backStep? dispatch(previousStep('')):dispatch(nextStep(step));
  const [contact, setContact] = useState<any>(false)

  const openContact = () => setContact(true)
  const closeContact = () => setContact(false)

  useEffect(() => {
    setContact((filterActive && (current === 'products')))
  }, [filterActive])

  const toForm = () => dispatch(nextStep("purchaseData"));

  const toCart = () => dispatch(nextStep('productsCart'))

  const selected = useSelector(getSelectedProductId)

  return (
    <StepStatusHeaderWrapper count={count} style={hide? {justifyContent: 'space-between'}:{}}>
      {(!hide && !hideAll) && <ButtonWrapper>
        <UnderlineButton onClick={onClick}>
          <ChevronLeft />
          {backLabel ? backLabel : content.getValue("back_button")}
        </UnderlineButton>
      </ButtonWrapper>}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: count? 'flex-start':'center'}}>
      {count ? (
        <div style={{display: 'flex'}}>
          {content.getValue("products_header")}
        </div>
      ) : (<div>
          {label}
        </div>
      )}
      {secondLabel && <div style={{fontSize: '24px'}}>{secondLabel}</div>}
      </div>
      {(!filter && !hideAll) && <UnderlineButton onClick={openContact}>
        {content.getValue('get_help')}
        <HelpOutline style={{marginLeft: '10px'}} />
      </UnderlineButton>}
      <ContactDrawer open={contact} onClose={closeContact} content={<div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{fontSize: '2.5em'}}>{content.getValue("contact_info_header")}</div>
        <div style={{fontSize: '1.1em', marginTop: '40px'}}>{content.getValue("contact_info_description")}</div>
        <div style={{display:'flex', alignItems: 'center', fontSize: '1.5em', marginTop: '40px'}}><MailOutline style={{fontSize: '2.2em', marginRight: '10px'}}/>{content.getValue("contact_info_mail")}</div>
        <div style={{display:'flex', alignItems: 'center', fontSize: '1.5em', marginTop: '10px'}}><Phone style={{fontSize: '2.2em', marginRight: '10px'}}/>{content.getValue("contact_info_phone")}</div>

      </div>}/>
      {/*{repeat && <ButtonWrapper style={{left: 'unset', right:'75px'}}>*/}
      {/*  {!hide && <UnderlineButton disabled={!selected} onClick={cart ? toForm: toCart}>*/}
      {/*    { cart? content.getValue("to_order"):content.getValue("products_select_cart_button")}*/}
      {/*      <ChevronRight />*/}
      {/*  </UnderlineButton>}*/}
      {/*</ButtonWrapper>}*/}
    </StepStatusHeaderWrapper>
  );
}

export default StepStatusHeader;
export interface IProductsHeader {
  count?: number;
  label: string;
  step: string;
  backLabel?: string;
  backStep?: boolean
  hideAll?: boolean
  cart?:boolean
  secondLabel?: string
  filter?: boolean
  repeat?: boolean
  hide?: boolean
}
