import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import "../../components/Screens/loader.css";
import content from "../../content";
import { ProductsActions } from "../../reducers/products/ActionsNames";
import { getOrderSid } from "../../reducers/products/selectors";
import nextStep from "../../actions/steps/nextStep";

const pulse = keyframes`
    0% {
      opacity: 0.65;
    }

    70% {
      opacity: 1;
    }

  100% {
    opacity: 0.65;
  }
`;

const SurveyFinishScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 2.5em;
  color: rgb(85, 85, 85);
  transform: scale(1);
  text-align: center;
  animation: ${pulse} 3s infinite;
`;

function PaymentWait() {
  const dispatch = useDispatch();
  const orderSid = useSelector(getOrderSid);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/order/sid/" + orderSid)
      .then((results) => results.json())
      .then((data) => {
        if ("paymentStatus" in data) {
          if (data["paymentStatus"] === 1) {
            dispatch(nextStep("paymentSuccess"));
          } else {
            setTimeout(() => {
              fetch(process.env.REACT_APP_API_URL + "/order/sid/" + orderSid)
                .then((results) => results.json())
                .then((data) => {
                  if ("paymentStatus" in data) {
                    if (data["paymentStatus"] === 1) {
                      dispatch(nextStep("paymentSuccess"));
                    } else {
                      fetch(
                        process.env.REACT_APP_API_URL +
                          "/order/payment/" +
                          orderSid,
                        { method: "PUT" }
                      )
                        .then((r) => r.json())
                        .then((data) => {
                          dispatch({
                            type: ProductsActions.SetOrderSid,
                            sid: data["sid"],
                          });
                          dispatch(nextStep("paymentFailed"));
                        });
                    }
                  } else {
                    dispatch(nextStep("paymentFailed"));
                  }
                });
            }, 10000);
          }
        } else {
          dispatch(nextStep("paymentFailed"));
        }
      });
  }, []);

  return (
    <SurveyFinishScreenWrapper>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <Label>{content.getValue("loading_payment")}</Label>
    </SurveyFinishScreenWrapper>
  );
}

export default PaymentWait;
