import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCupon,
  getDelivery,
  getFormValues,
  getSelectedProduct,
} from "../../reducers/products/selectors";
import styled, { css } from "styled-components";
import content from "../../content";
import { Img } from "./Product";
import IconButton from "@material-ui/core/IconButton";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { ProductsActions } from "../../reducers/products/ActionsNames";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const Price = styled.div<{ cupon?: boolean }>`
  color: black;
  font-weight: 500;
  font-size: 1.15em;
  position: relative;
  ${({ cupon }) =>
    cupon
      ? css`
          color: #a5a5a5;
          &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid;
            border-color: inherit;
            transform: rotate(-5deg);
          }
        `
      : ""}
`;

function Products({ delivery }: IProducts) {
  const deliveryOption = useSelector(getDelivery);
  const products = useSelector(getSelectedProduct);
  const userForm = useSelector(getFormValues);
  const cupon = useSelector(getCupon);

  const dispatch = useDispatch();

  const removeProduct = (product) => () => {
    dispatch({ type: ProductsActions.RemoveProduct, id: product.id });
  };

  return (
    <Wrapper style={{ boxSizing: "border-box" }}>
      {products.map((p) => (
        <div
          style={{
            marginTop: "15px",
            borderRadius: "5px",
            border: "1px solid #dcdcdc",
            backgroundColor: "white",
            padding: "14px",
            display: "flex",
            width: "75%",
            borderBottom: "1px solid #dfdfdf",
          }}
        >
          <Img style={{ width: "8vw" }} src={p.image} />
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              flexDirection: "column",
              fontWeight: 600,
              marginRight: "20px",
              width: "100%",
            }}
          >
            {p.name}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "normal",
                marginTop: "75px",
                marginLeft: "-52px",
              }}
            >
              {p.count} x{" "}
              {(
                (userForm &&
                userForm.cupon &&
                userForm.cupon.toLowerCase() === "kielce2020"
                  ? Number(p.price) * 0.9
                  : Number(p.price)) * 1.23
              ).toFixed(2)}{" "}
              {content.getValue("products_price")}
            </div>
          </div>
          <IconButton
            style={{ placeSelf: "center" }}
            onClick={removeProduct(p)}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        </div>
      ))}

      <Price cupon={!!cupon} style={{ marginTop: "15px" }}>{`${(
        products.reduce((a, b) => a + Number(b.price) * b.count, 0) * 1.23 +
        (delivery && deliveryOption ? Number(deliveryOption.price) : 0)
      ).toFixed(2)} ${content.getValue("products_price")}`}</Price>
      {!!cupon && (
        <Price>
          {`${(
            products.reduce((a, b) => a + Number(b.price) * b.count, 0) * 1.23 +
            (cupon
              ? -(
                  ((products.reduce(
                    (a, b) => a + Number(b.price) * b.count,
                    0
                  ) *
                    cupon.percent) /
                    100) *
                  1.23
                ) - cupon.amount
              : 0) +
            (delivery && deliveryOption ? Number(deliveryOption.price) : 0)
          ).toFixed(2)} ${content.getValue("products_price")}`}
        </Price>
      )}
    </Wrapper>
  );
}

export interface IProducts {
  delivery?: boolean;
}

export default Products;
