import React, { useState } from "react";
import styled, { css } from "styled-components";
import { IProduct } from "../../products";
import { RoundButton } from "../../components/Screens";
import UnderlineButton from "../../components/Screens/UnderlineButton";
import { ProductDrawer } from "../../components/Products";
import { useDispatch, useSelector } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import selectProduct from "../../actions/products/selectProduct";
import content from "../../content";
import { IDelivery } from "../../reducers/products";
import {
  getCupon,
  getDelivery,
  getFormValues,
  getSelectedProductId,
} from "../../reducers/products/selectors";
import { isMany, isRepeat } from "../../reducers/steps/selectors";
import NumberInput from "../../components/Products/NumberInput";
import { ProductsActions } from "../../reducers/products/ActionsNames";
import { IFeature } from "../../reducers/steps";
import { Input } from "../../components/Steps";
import { useMediaQuery } from "@material-ui/core";
import checkicon from "../../assets/checkicon.png";

interface IPreview {
  preview?: boolean;
}

const ProductWrapper = styled.div<IPreview>`
  height: 100vh;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 0px;
  background-color: ${(props) =>
    props.preview ? "rgb(245, 245, 245)" : "unset"};
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  font-size: 1.1em;
`;

const Offer = styled.div`
  font-size: 1em;
  margin-bottom: 40px;
`;

const Name = styled.div`
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
`;

export const Img = styled.img<{ preview?: boolean }>`
  width: 12em;
  height: ${({ preview }) => (preview ? "auto" : "100%")};
  margin-bottom: 40px;
`;

const List = styled.ul<IPreview>`
  list-style: disc;
  font-size: 0.9em;
  list-style-image: url(${checkicon});
  margin-left: ${(props) => (props.preview ? "0" : "15%")};

  align-self: ${(props) => (props.preview ? "center" : "baseline")};
`;

const ListItem = styled.li`
  font-weight: 300;
  line-height: 1.5;
`;

const PriceWrapper = styled.div<IPreview>`
  margin-top: ${(props) => (props.preview ? "100px" : "auto")};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Price = styled.div<{ cupon?: boolean } & IPreview>`
  color: black;
  font-weight: 500;
  font-size: 1.25em;
  position: relative;
  ${({ cupon, preview }) =>
    cupon && preview
      ? css`
          color: #a5a5a5;
          &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid;
            border-color: inherit;
            transform: rotate(-5deg);
          }
        `
      : ""}
`;

export const NewProduct = ({
  cart,
  count,
  product,
  delivery,
  preview = false,
}: IProductProps) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const deliveryOption = useSelector(getDelivery);

  const selected = useSelector(getSelectedProductId);

  const repeat = useSelector(isRepeat);
  const many = useSelector(isMany);

  const desktop = useMediaQuery("(min-width:800px)");

  const offer = {
    "0": content.getValue("products_offer_minimal"),
    "1": content.getValue("products_offer_optimal"),
    "2": content.getValue("products_offer_best"),
  };

  const onClick = () => {
    dispatch(selectProduct(product.id, many));
    if (!repeat) {
      dispatch(nextStep("purchaseData"));
    } else {
      dispatch(nextStep("productsCart"));
    }
  };

  const toggleOpen = () => setOpen(!open);

  const changeCount = (e: any) => {
    dispatch({
      type: ProductsActions.ChangeCount,
      id: product.id,
      count: e.currentTarget.value,
    });
  };

  const removeProduct = () => {
    dispatch({ type: ProductsActions.RemoveProduct, id: product.id });
  };

  const featureFilters = [
    "Odbiór ciepłej wody:",
    "Pojemność podgrzewacza (l.):",
    "Średnie zapotrzebowanie dla:",
  ];

  return (
    <ProductWrapper
      style={{
        borderRadius: "5px",
        border: "1px solid #dcdcdc",
        flexGrow: "unset",
        height: "unset",
        marginBottom: "15px",
        alignItems: desktop ? "flex-start" : "center",
        justifyContent: "flex-start",
        padding: "15px",
      }}
      preview={preview}
    >
      {!preview && !cart && (
        <Offer style={{ marginBottom: "10px" }}>
          {offer[product.offerType]}
        </Offer>
      )}
      <Name>{product.name}</Name>
      <div
        style={{
          display: "flex",
          flexDirection: desktop ? "row" : "column",
          width: "100%",
          alignItems: desktop ? "unset" : "center",
        }}
      >
        <Img style={{ marginBottom: "20px" }} src={product.image} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingRight: "10px",
          }}
        >
          <List preview={preview}>
            {product.features &&
              product.features
                .filter((f) => featureFilters.includes(f.nameVerbose))
                .map((f) => (
                  <ListItem key={f.id}>
                    {f.nameVerbose} {f.valueVerbose}
                  </ListItem>
                ))}
          </List>
          <PriceWrapper
            style={{
              marginTop: "40px",
              display: "flex",
              flexDirection: desktop ? "row" : "column",
              marginLeft: desktop ? "12%" : "0%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            preview={true}
          >
            {cart && (
              <NumberInput
                value={count}
                onChange={changeCount}
                wrapperStyle={{ marginBottom: "10px" }}
              />
            )}
            <div>
              <Price>{content.getValue("price")}</Price>
              <Price>
                {cart ? `${count} x ` : ""}

                {`${Number(
                  Number(product.price) *
                    1.23 *
                    (preview
                      ? selected.find((s) => s.id === product.id)?.count || 1
                      : 1) +
                    (delivery && deliveryOption
                      ? Number(deliveryOption.price)
                      : 0)
                ).toFixed(2)} ${content.getValue("products_price")}`}
              </Price>
            </div>
            {!preview && (
              <>
                {!cart && (
                  <RoundButton
                    style={{
                      padding: "5px 55px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    onClick={onClick}
                  >
                    {repeat
                      ? content.getValue("products_select_cart_button")
                      : content.getValue("products_select_button")}
                  </RoundButton>
                )}
                {cart && (
                  <UnderlineButton onClick={removeProduct}>
                    {content.getValue("cart_remove")}
                  </UnderlineButton>
                )}
                {/*<UnderlineButton*/}
                {/*    onClick={toggleOpen}*/}
                {/*    style={{ textTransform: "none", fontWeight: 300 }}*/}
                {/*>*/}
                {/*  {content.getValue("products_more_info")}*/}
                {/*</UnderlineButton>*/}
              </>
            )}
          </PriceWrapper>
        </div>
      </div>
      {/*<ProductDrawer open={open} onClose={toggleOpen} product={product} />*/}
    </ProductWrapper>
  );
};

function Product({
  cart,
  count,
  product,
  delivery,
  preview = false,
}: IProductProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const deliveryOption = useSelector(getDelivery);

  const selected = useSelector(getSelectedProductId);

  const repeat = useSelector(isRepeat);
  const many = useSelector(isMany);
  const userForm = useSelector(getFormValues);

  const cupon = useSelector(getCupon);

  const offer = {
    "0": content.getValue("products_offer_minimal"),
    "1": content.getValue("products_offer_optimal"),
    "2": content.getValue("products_offer_best"),
  };

  const onClick = () => {
    dispatch(selectProduct(product.id, many));
    if (!repeat) {
      dispatch(nextStep("purchaseData"));
    } else {
      dispatch(nextStep("productsCart"));
    }
  };

  const toggleOpen = () => setOpen(!open);

  const changeCount = (e: any) => {
    dispatch({
      type: ProductsActions.ChangeCount,
      id: product.id,
      count: e.currentTarget.value,
    });
  };

  const removeProduct = () => {
    dispatch({ type: ProductsActions.RemoveProduct, id: product.id });
  };

  const featureFilters = [
    "Odbiór ciepłej wody:",
    "Pojemność podgrzewacza (l.):",
    "Średnie zapotrzebowanie dla:",
  ];

  return (
    <ProductWrapper preview={preview} style={{ backgroundColor: "#00a04f" }}>
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #dcdcdc",
          padding: "50px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        {!preview && !cart && <Offer>{offer[product.offerType]}</Offer>}
        <Name>{product.name}</Name>
        <Img preview={preview} src={product.image} />
        <List preview={preview}>
          {product.features &&
            product.features
              .filter((f) => featureFilters.includes(f.nameVerbose))
              .map((f) => (
                <ListItem key={f.id}>
                  {f.nameVerbose} {f.valueVerbose}
                </ListItem>
              ))}
        </List>
        <PriceWrapper preview={true} style={{ marginTop: "30px" }}>
          {cart && (
            <NumberInput
              value={count}
              onChange={changeCount}
              wrapperStyle={{ marginBottom: "10px" }}
            />
          )}
          <Price preview={preview} cupon={!!cupon}>
            {cart ? `${count} x ` : ""}
            {`${Number(
              Number(product.price) *
                1.23 *
                (preview
                  ? selected.find((s) => s.id === product.id)?.count || 1
                  : 1) +
                (delivery && deliveryOption ? Number(deliveryOption.price) : 0)
            ).toFixed(2)} ${content.getValue("products_price")}`}
          </Price>
          {!!cupon && preview && (
            <Price>
              {cart ? `${count} x ` : ""}
              {`${Number(
                (cupon
                  ? Number(product.price) * 1.23 -
                    (Number(product.price) * 1.23 * cupon.percent) / 100 -
                    Number(cupon.amount)
                  : Number(product.price) * 1.23) *
                  (preview
                    ? selected.find((s) => s.id === product.id)?.count || 1
                    : 1) +
                  (delivery && deliveryOption
                    ? Number(deliveryOption.price)
                    : 0)
              ).toFixed(2)} ${content.getValue("products_price")}`}
            </Price>
          )}
          {!preview && (
            <>
              {!cart && (
                <RoundButton
                  customBackgroundColor={"rgba(0, 0, 0, 0)"}
                  style={{
                    fontSize: "1.25vw",
                    padding: "5px 75px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                  onClick={onClick}
                >
                  {repeat
                    ? content.getValue("products_select_cart_button")
                    : content.getValue("products_select_button")}
                </RoundButton>
              )}
              {cart && (
                <UnderlineButton onClick={removeProduct}>
                  {content.getValue("cart_remove")}
                </UnderlineButton>
              )}
              <UnderlineButton
                onClick={toggleOpen}
                style={{ textTransform: "none", fontWeight: 300 }}
              >
                {content.getValue("products_more_info")}
              </UnderlineButton>
            </>
          )}
        </PriceWrapper>
        <ProductDrawer open={open} onClose={toggleOpen} product={product} />
      </div>
    </ProductWrapper>
  );
}

export default Product;
export interface IProductProps {
  product: IProduct;
  preview?: boolean;
  cart?: boolean;
  delivery?: boolean;
  count?: number;
}
