import React from "react";
import {
  FormControlLabel as FormControlLabelMUI,
  Checkbox as CheckboxMUI,
  FormControlLabelProps,
} from "@material-ui/core";
import { FieldInputProps, FieldRenderProps } from "react-final-form";
import styled from "styled-components";

const FormControlLabel = styled(FormControlLabelMUI)<
  FormControlLabelProps & { fontSize?: string; fontColor?: string }
>`
  color: black;

  & > .MuiFormControlLabel-label {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "1vw")};
    color: ${(props) => (props.fontColor ? props.fontColor : "inherit")};
  }
  & > .Mui-checked {
    color: #ffbd00;
  }
`;

function Checkbox({
  label,
  subLabel,
  input,
  fontSize,
  fontColor,
  style,
  wrapperStyle,
  ...rest
}: ICheckbox) {
  return (
    <div
      style={{
        ...{ display: "flex", flexDirection: "column" },
        ...wrapperStyle,
      }}
    >
      <FormControlLabel
        fontSize={fontSize}
        fontColor={fontColor}
        control={
          <CheckboxMUI
            {...rest}
            {...(input as any)}
            color="primary"
            style={{ ...{}, ...style }}
          />
        }
        label={label}
      />
      {subLabel && (
        <h5
          style={{ marginLeft: "30px" }}
          dangerouslySetInnerHTML={{ __html: subLabel }}
        />
      )}
      {rest.meta.error && rest.meta.touched && (
        <span style={{ color: "darkred", marginLeft: "30px" }}>
          {rest.meta.error}
        </span>
      )}
    </div>
  );
}

export default Checkbox;
export interface ICheckbox extends FieldRenderProps<any> {
  label: string;
  subLabel?: string;
  fontColor?: string;
}
