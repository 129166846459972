import React, { useEffect, useState } from "react";
import {
  InfoDrawer,
  RoundButton,
  ScreenWrapper,
  StepControls,
} from "../../components/Screens";
import { IconButton, LinearProgress } from "@material-ui/core";
import styled from "styled-components";
import { HelpOutline } from "@material-ui/icons";
import { Answer, Choice, YesNo } from "../Steps";
import { IAnswers, IStep, QuestionType } from "../../steps";
import { useDispatch, useSelector } from "react-redux";
import newBenefits from "../../actions/benefits/newBenefits";
import setAnswer from "../../actions/steps/setAnswer";
import {
  getCurrentStepAnswers,
  getOverrideStep,
  getRepeat,
  getStepOneAnwser,
} from "../../reducers/steps/selectors";
import { CustomStep4 } from "../../components/Steps";
import CustomStep6 from "../../components/Steps/Custom/CustomStep6";
import content from "../../content";
import UnderlineButton from "../../components/Screens/UnderlineButton";

const Progress = styled(LinearProgress)`
  background-color: rgb(227, 230, 232);
  width: 65%;
  height: 8px;
  border-radius: 25px;
  & .MuiLinearProgress-bar {
    background-color: #ffbd00;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Question = styled.div<{ subQuestion?: boolean }>`
  font-size: ${(props) => (props.subQuestion ? 1.8 : 2.3)}em;
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
  font-weight: 600;
  color: black;
  margin: 20px 0px ${(props) => (props.subQuestion ? 10 : 40)}px;
`;

const SubQuestion = styled.div`
  font-size: 1em;
  color: rgb(85, 85, 85);
  margin-bottom: 40px;
`;

function StepScreen({ step }: IStepScreen) {
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState("");
  const stepValue = useSelector(getCurrentStepAnswers);
  const override = useSelector(getOverrideStep);
  const stepOne = useSelector(getStepOneAnwser);

  const repeat = useSelector(getRepeat);

  const dispatch = useDispatch();

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  useEffect(() => {
    if (stepValue) {
      setNext(stepValue.next);
      dispatch(newBenefits(stepValue.benefits));
    } else {
      setNext("");
    }
  }, [stepValue]);

  if (!step) {
    return <></>;
  }

  const onChange = (a: IAnswers, value?: string) => {
    setNext(a.nextStep ? a.nextStep : override);
    dispatch(
      setAnswer({
        id: a.id,
        step: step.step,
        answer: a.name,
        answerVerbose: a.content,
        next: a.nextStep ? a.nextStep : override,
        value: value,
        benefits: a.benefits,
        features: a.features,
        values: a.values,
        customFilters: a.customFilters,
        overrideNext: a.overrideNextStep,
        repeat: a.repeat,
        liters: a.liters,
      } as any)
    );
    dispatch(newBenefits(a.benefits));
  };

  const stepContent = () => {
    if (step.step === "6" || step.step === "5") {
      return (
        <CustomStep6
          step={step}
          value={stepValue && stepValue.answer}
          onChange={onChange}
        />
      );
    }
    switch (step.type) {
      case QuestionType.Answer:
        return (
          <Answer
            value={stepValue && stepValue.value}
            step={step}
            onChange={onChange}
          />
        );
      case QuestionType.Choice:
        return (
          <Choice
            value={stepValue && stepValue.answer}
            step={step}
            onChange={onChange}
          />
        );
      case QuestionType.YesNo:
        return (
          <YesNo
            value={stepValue && stepValue.answer}
            step={step}
            onChange={onChange}
          />
        );
      case QuestionType.Custom:
        if (String(step.step) === "4" || String(step.step) === "7") {
          return (
            <CustomStep4 setNext={setNext} onChange={onChange} step={step} />
          );
        }
        return <></>;
    }
  };

  return (
    <ScreenWrapper height={true}>
      <ProgressWrapper>
        <Progress variant={"determinate"} value={step.progression} />
        <UnderlineButton onClick={openDrawer}>
          {content.getValue("get_help")}
          <HelpOutline style={{ marginLeft: "10px" }} />
        </UnderlineButton>
        <InfoDrawer open={open} onClose={closeDrawer} content={step.info} />
      </ProgressWrapper>
      <Question subQuestion={Boolean(step.subQuestion)}>
        {step.question.replace("%i%", String(repeat))}
      </Question>
      {step.subQuestion && <SubQuestion>{step.subQuestion}</SubQuestion>}
      {stepContent()}
      <StepControls
        disabled={
          (step.type === QuestionType.Answer ||
            step.type === QuestionType.Custom) &&
          !(stepValue && stepValue.value)
        }
        next={
          stepOne?.answer === "dailyUsage" && step.step === "4" ? "13" : next
        }
        thisStep={step.step}
      />
    </ScreenWrapper>
  );
}

export default StepScreen;
export interface IStepScreen {
  step?: IStep | undefined;
}
