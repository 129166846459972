import React from "react";
import styled from "styled-components";

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a04f;
  border-radius: 50%;
  color: white;
  width: 6em;
  height: 6em;
  & > svg {
    font-size: 5.5em;
  }
`;

const IconWrapper = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  margin-top: 15px;
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
  color: white;
`;

function Benefit({ icon, label, split }: IBenefitProps) {
  return (
    <IconWrapper>
      <Icon>{icon}</Icon>
      {!split && <Label>{label}</Label>}
    </IconWrapper>
  );
}

export default Benefit;
export interface IBenefitProps {
  label: string;
  split?: boolean;
  icon: React.ReactNode;
}
