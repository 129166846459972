import {
  FormControlLabel as FormControlLabelMUI,
  FormControlLabelProps,
} from "@material-ui/core";
import styled from "styled-components";
import React, { ChangeEvent, useEffect, useState } from "react";

const FormControlLabel = styled(FormControlLabelMUI)<
  FormControlLabelProps & { checked?: boolean }
>`
  background-color: white;
  margin-right: 0;
  margin-left: 0;
  font-size: 1em;
  font-weight: bold;
  padding: 8px 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: ${(props) =>
    props.checked ? '1px solid #ffbd00' : "1px solid #dcdcdc"};
  color: black;

  & > .Mui-checked {
    color: #ffbd00;
  }
`;

export default FormControlLabel;
