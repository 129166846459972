import React, { ChangeEvent, useState } from "react";
import {
  RadioGroup as RadioGroupMUI,
  RadioGroupProps,
} from "@material-ui/core";

function RadioGroup({ children, value, ...rest }: RadioGroupProps) {
  const [checked, setChecked] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>, value: string) =>
    setChecked(value);
  const controls = React.Children.map(children, (child) =>
    React.cloneElement(child as any, {
      checked:
        (child as any).props.value === checked ||
        (child as any).props.value === value,
    })
  );

  return <RadioGroupMUI children={controls} {...rest} onChange={onChange} />;
}

export default RadioGroup;
