export enum StepsActions {
  NextStep = "Next step",
  PreviousStep = "Previous step",
  SetAnswer = "Set answer",
  FetchSteps = "Fetch steps",
  InsertSteps = "Insert steps",
  FetchContent = "Fetch content",
  InsertContent = "Insert content",
  Reset = "Reset steps",
  SetCustom = "Set custom",
  SetUnits = "Set units",
  SetPeriod = "Set period",
  NextWaterPoint = "Next water point",
  RemoveAnswer = "Remove answer",
  SetState = "Set step state",
  InsertVars = "Insert vars",
  FetchVars = "Fetch vars",
}
