import React, {useEffect} from "react";
import styled, { keyframes } from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import "../../components/Screens/loader.css";
import loadProducts from "../../actions/products/loadProducts";
import {getProducts, getProductsLoading} from "../../reducers/products/selectors";
import {getContent, getLoading} from "../../reducers/steps/selectors";

const pulse = keyframes`
    0% {
      opacity: 0.65;
    }

    70% {
      opacity: 1;
    }

  100% {
    opacity: 0.65;
  }
`;

const StartLoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 2.5em;
  color: rgb(85, 85, 85);
  transform: scale(1);
  animation: ${pulse} 3s infinite;
`;

function StartLoading() {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading)
  const content = useSelector(getContent)

  useEffect(() => {
    if(!loading) {
      if (content.length !== 0) {
        dispatch(nextStep('start'))
      }
    }
  }, [loading, content])

  return (
    <StartLoadingWrapper>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <Label>Pro Eco Solutions</Label>
    </StartLoadingWrapper>
  );
}

export default StartLoading;
