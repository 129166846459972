import React from "react";
import { Button, ButtonProps } from "@material-ui/core";

function UnderlineButton(props: ButtonProps) {
  return (
    <Button {...props} style={{ color: "rgb(85, 85, 85)", ...props.style }} />
  );
}

export default UnderlineButton;
