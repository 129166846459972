import { AnyAction } from "redux";
import initialStore from "./initialStore";
import { BenefitsAction } from "./actionsNames";
import { StepsActions } from "../steps/actionsNames";
import { act } from "react-dom/test-utils";

const pushUnique = (arr: number[], arr2: number[]) => {
  const arrCopy = [...arr];

  arr2.forEach((e) => {
    if (arrCopy.indexOf(e) === -1) {
      arrCopy.push(e);
    }
  });

  return arrCopy;
};

const without = (arr: number[], arr2: number[]) => {
  let arrCopy = [...arr];
  arr2.forEach((e) => (arrCopy = arrCopy.filter((a) => a !== e)));

  return arrCopy;
};

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export default (store: IBenefitsStore = initialStore, action: AnyAction) => {
  switch (action.type) {
    case BenefitsAction.NewBenefits:
      return {
        ...store,
        uniqueBenefits:
          store.uniqueBenefits.length === 0
            ? action.benefits.filter((b) => !store.summary.find((s) => s === b))
            : store.uniqueBenefits,
        stepBenefits: action.benefits,
        summary: pushUnique(store.summary, action.benefits),
      };
    case BenefitsAction.InsertBenefits:
      return {
        ...store,
        benefits: action.benefits,
      };
    case BenefitsAction.SetState:
      return {
        ...store,
        stepBenefits: action.stepBenefits,
        summary: action.summaryBenefits,
      };
    case StepsActions.NextStep:
      return {
        ...store,
        stepBenefits: [],
        uniqueBenefits: [],
      };
    case StepsActions.PreviousStep:
      return {
        ...store,
        summary: without(store.summary, store.stepBenefits),
        stepBenefits: [],
        uniqueBenefits: [],
      };
    case StepsActions.SetAnswer:
      return {
        ...store,
        summary: [
          ...store.summary.filter(
            (s) => !store.uniqueBenefits.find((b) => b === s)
          ),
        ],
        uniqueBenefits: [],
      };
    default:
      return store;
  }
};

export interface IBenefit {
  id: number;
  type: number;
  content: string;
  highlighted?: number;
}

export interface IBenefitsStore {
  summary: number[];
  stepBenefits: number[];
  uniqueBenefits: number[];
  benefits: IBenefit[];
}
