import React from "react";
import StepStatus, { StatusIconType } from "../../components/Steps/StepStatus";
import { Input } from "../../components/Steps";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import { ProductsScreenWrapper, RoundButton } from "../../components/Screens";
import content from "../../content";

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
`;

function PhoneSubmitScreen() {
  const dispatch = useDispatch();

  const onClick = () => dispatch(nextStep("start"));

  const info = content.getValue("phone_submitted_info");

  return (
    <ProductsScreenWrapper>
      <StepStatus
        iconType={StatusIconType.Success}
        count={0}
        backStep={"start"}
        info={info}
        headerLabel={content.getValue("phone_submitted_header")}
      />
    </ProductsScreenWrapper>
  );
}

export default PhoneSubmitScreen;
