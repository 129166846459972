import React, { useState } from "react";
import { ScreenWrapper, StepControls } from "../../components/Screens";
import { Divider } from "@material-ui/core";
import content from "../../content";
import styled from "styled-components";
import { Column } from "./PurchaseForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeliveryId,
  getPaymentDelivery,
} from "../../reducers/products/selectors";
import Checkbox from "../../components/Steps/Checkbox";
import { IDelivery } from "../../reducers/products";
import setPaymentMethod from "../../actions/products/setPaymentMethod";
import setDelivery from "../../actions/products/setDelivery";
import Scrollbars from "react-custom-scrollbars";
import order from "../../actions/products/order";

const Header = styled.div`
  font-size: 2.5em;
  margin-bottom: 40px;
  color: rgb(85, 85, 85);
`;

function PurchaseDelivery({}: IPurchaseDelivery) {
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const delivery = useSelector(getPaymentDelivery);
  const deliveryId = useSelector(getDeliveryId);

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name);
    dispatch(setDelivery(e.currentTarget.name));
  };

  const onNext = () => {
    dispatch(order());
  };

  return (
    <ScreenWrapper backgroundColor={"white"}>
      <Divider style={{ width: "15%", margin: "40px 0px" }} />
      <Header>{content.getValue("delivery_select_header")}</Header>
      <Column>
        {delivery
          .sort((a, b) => Number(a.position) - Number(b.position))
          .map((d: IDelivery) => (
            <Checkbox
              wrapperStyle={{ marginBottom: "20px" }}
              key={d.id}
              name={d.id}
              fontSize={"1em"}
              checked={Number(value) === Number(d.id)}
              label={d.name}
              subLabel={
                content.getValue("delivery_delay") +
                ": " +
                d.delay +
                "<br/>" +
                content.getValue("delivery_fee") +
                ": " +
                Number(d.price).toFixed(2) +
                ` ${content.getValue("products_price")}`
              }
              input={{} as any}
              meta={{} as any}
              onChange={onChange}
            />
          ))}
      </Column>
      <StepControls
        next={"purchaseInfo"}
        onNext={onNext}
        thisStep={"purchaseDelivery"}
        disabled={!deliveryId}
        buttonLabel={content.getValue("payment_full_button")}
      />
    </ScreenWrapper>
  );
}

export interface IPurchaseDelivery {}

export default PurchaseDelivery;
