import React from "react";
import { Info } from "@material-ui/icons";
import styled from "styled-components";
import content from "../../content";

const PurchaseInfoAlertWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.9em;
  margin: 20px 0px;
`;

function PurchaseInfoAlert({}: IPurchaseInfoAlert) {
  return content.getValue("payment_info_alert").trim() ? (
    <PurchaseInfoAlertWrapper>
      <Info />
      <div style={{ marginLeft: "10px" }}>
        {content.getValue("payment_info_alert")}
      </div>
    </PurchaseInfoAlertWrapper>
  ) : (
    <></>
  );
}

export interface IPurchaseInfoAlert {}

export default PurchaseInfoAlert;
