import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import applySagas from "./saga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  compose(applyMiddleware(logger, sagaMiddleware))
);

applySagas(sagaMiddleware);

export default store;
