import { combineReducers } from "redux";
import steps from "./steps";
import benefits from "./benefits";
import products from "./products";

const rootReducer = combineReducers({
  steps,
  benefits,
  products,
});

export default rootReducer;
export type State = ReturnType<typeof rootReducer>;
