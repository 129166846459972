import * as Effects from "redux-saga/effects";
import { ProductsActions } from "../reducers/products/ActionsNames";
import {
  getCupon,
  getDelivery,
  getDeliveryId,
  getFormValues,
  getPaymentMethod,
  getSelectedProduct,
} from "../reducers/products/selectors";
import { Action } from "redux";
import { IPurchaseFormValues } from "../containers/Purchase/PurchaseForm";
import content from "../content";
import React from "react";
import { getBenefitsMapped } from "../reducers/benefits/selectors";
import { useSelector } from "react-redux";
import {
  getStepAnswers,
  getSteps,
  getValues,
  getVars,
} from "../reducers/steps/selectors";
import { IStepAnswers } from "../reducers/steps";

function* order() {
  const product = yield Effects.select(getSelectedProduct);
  const userForm = yield Effects.select(getFormValues);
  const paymentMethod = yield Effects.select(getPaymentMethod);
  const deliveryId = yield Effects.select(getDeliveryId);
  const delivery = yield Effects.select(getDelivery);
  const cupon = yield Effects.select(getCupon);
  const benefits = yield Effects.select(getBenefitsMapped);
  const answers = yield Effects.select(getStepAnswers);
  const steps = yield Effects.select(getSteps);

  const values = yield Effects.select(getValues);
  const vars = yield Effects.select(getVars);

  const filterValues = (name: string) => {
    const value = (values as any)
      .reduce((a: any, b: any) => a.concat(b.values), [])
      .find((v: any) => v.name === name);

    const globalValue = vars.find((variable) => variable.name === name);

    if (!value && !globalValue) {
      return {
        ...value,
      };
    }

    if (value && value.value === "" && name === "litry") {
      return {
        ...value,
        value: (answers as any).find((s: any) => s.values.includes(value))
          ?.liters,
      };
    }

    if (value && value.value === "") {
      return {
        ...value,
        value: answers.find((s) => s.values.includes(value))?.answerVerbose,
      };
    }

    if (!value && globalValue) {
      return globalValue;
    }

    return value;
  };

  const formatBenefit = (content: string) => {
    if (content.match(new RegExp("%([^%]*)%"))) {
      const newContent = content.replace(
        new RegExp("%([^%]*)%", "g"),
        (value: string) => {
          const newValue = value.replace(
            new RegExp("!(\\w*)", "g"),
            (v: string) => {
              return filterValues(v.substr(1))?.value || v;
            }
          );

          try {
            if (newValue.match(new RegExp("!(\\w*)", "g"))) {
              return "%undefined%";
            }
            var calc = newValue.replace(/,/g, ".").replace(/[^-()\d/*+.]/g, "");

            return isNaN(eval(calc))
              ? eval(calc)
              : Number(eval(calc)).toFixed(2);
          } catch (e) {
            return newValue.slice(1, -1);
          }
        }
      );

      return newContent;
    }
    return content;
  };

  const productPrice =
    product.reduce((a, b) => a + Number(b.price) * b.count, 0) * 1.23;
  const price = cupon
    ? productPrice - (productPrice * cupon.percent) / 100 - cupon.amount
    : productPrice;

  try {
    const ans = (answers as any).map((a: any) => ({
      question: steps.find((s: any) => s.step === a.step).question,
      answer: a.answerVerbose,
    }));

    const response = yield fetch(process.env.REACT_APP_API_URL + "/order/", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify({
        user: {
          ...userForm.user,
          nip: userForm.user.nip ? userForm.user.nip : "",
          phone: userForm.user.phone ? userForm.user.phone : "",
        },
        address: userForm.address,
        invoice: userForm.invoice,
        addressCheck: userForm.addressCheck,
        invoiceCheck: userForm.invoiceCheck,
        product: product,
        benefits: benefits
          .map((b) => ({
            ...b,
            content: formatBenefit(b.content),
          }))
          .filter((b) => !b.content.match(new RegExp("%([^%]*)%"))),
        answers: ans,
        paymentMethod: paymentMethod,
        deliveryId: deliveryId,
        cupon: cupon ? cupon.id : undefined,
        price:
          (price + Number(delivery.price)).toFixed(2) +
          " " +
          content.getValue("products_price"),
      }),
    });

    const json = yield response.json();

    yield Effects.put({ type: ProductsActions.SetOrderId, id: json.id });
    yield Effects.put({
      type: ProductsActions.SetOrderToken,
      paymentToken: json.paymentToken,
    });
  } catch (e) {
    console.error(e);
  }
}

export default function* () {
  yield Effects.takeLatest(ProductsActions.OrderProduct, order);
}
