import styled from "styled-components";

const ProductsScreenWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 30px 50px 30px 50px;
  color: rgb(85, 85, 85);
  box-sizing: border-box;
`;

export default ProductsScreenWrapper;
