import React from "react";
import styled from "styled-components";
import UnderlineButton from "./UnderlineButton";
import { ArrowLeft, ArrowRight, ChevronLeft } from "@material-ui/icons";
import { RoundButton } from "./index";
import previousStep from "../../actions/steps/previousStep";
import { useDispatch } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import content from "../../content";

const StepControlsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

function StepControls({
  disabled,
  thisStep,
  next,
  buttonLabel,
  onNext,
  block,
}: IStepControls) {
  const dispatch = useDispatch();

  const prev = () => dispatch(previousStep(thisStep));
  const nextstep = () => {
    if (!block) {
      if (onNext) onNext();
      dispatch(nextStep(next));
      window.scrollTo(0, 0);
    }
  };

  return (
    <StepControlsWrapper>
      <UnderlineButton onClick={prev}>
        <ArrowLeft style={{ marginRight: "10px" }} />
        {content.getValue("back_button")}
      </UnderlineButton>
      <RoundButton
        type={"submit"}
        onClick={nextstep}
        disabledLabel={content.getValue("step_disabled_info_tooltip")}
        disabled={disabled || !next}
        style={{ fontWeight: 500, padding: "6px 25px" }}
      >
        {buttonLabel ? buttonLabel : content.getValue("next_button")}
        <div style={{ display: "flex", width: "34px" }}>
          <ArrowRight style={{ marginLeft: "5px" }} />
        </div>
      </RoundButton>
    </StepControlsWrapper>
  );
}

export default StepControls;
export interface IStepControls {
  next: string;
  thisStep: string;
  buttonLabel?: string;
  onNext?: () => void;
  block?: boolean;
  disabled?: boolean;
}
