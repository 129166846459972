import * as Effects from "redux-saga/effects";
import { StepsActions } from "../reducers/steps/actionsNames";
import { IStep } from "../reducers/steps";

function* fetchSteps() {
  const response = yield fetch(process.env.REACT_APP_API_URL + "/step/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  let steps: IStep[] = yield response.json();

  const mappedSteps = steps.map((s) => ({
    ...s,
    ansewers: s.ansewers.map((a) => ({
      ...a,
      benefits: a.benefits,
      features: a.features.filter((f) => !f.deleted)
    })),
    answers: s.ansewers.map((a) => ({
      ...a,
      benefits: a.benefits.map((b) => b.id),
      features: a.features.filter((f) => !f.deleted).map((f) => f.id)
    })),
  }));

  yield Effects.put({ type: StepsActions.InsertSteps, steps: mappedSteps });
}

export default function* () {
  yield Effects.takeLatest(StepsActions.FetchSteps, fetchSteps);
}
