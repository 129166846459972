import { SagaMiddleware } from "redux-saga";
import fetchSteps from "./fetchSteps";
import fetchBenefits from "./fetchBenefits";
import fetchContent from "./fetchContent";
import placeOrder from "./placeOrder";
import fetchDelivery from "./fetchDelivery";
import loadProducts from "./loadProducts";
import submitPhone from "./submitPhone";
import fetchVars from "./fetchVars";

export default function (middleware: SagaMiddleware<{}>) {
  middleware.run(fetchBenefits);
  middleware.run(fetchSteps);
  middleware.run(fetchContent);
  middleware.run(placeOrder);
  middleware.run(fetchDelivery);
  middleware.run(loadProducts);
  middleware.run(submitPhone);
  middleware.run(fetchVars);
}
