import React from 'react'
import Modal from "@material-ui/core/Modal";
import {useSelector} from "react-redux";
import {getSteps, getStepAnswers} from "../../reducers/steps/selectors";
import styled from 'styled-components';
import {getEcoBenefits, getFundsBenefits, getTimeBenefits} from "../../reducers/benefits/selectors";
import {getProducts, getProductsAll} from "../../reducers/products/selectors";
import {IProduct} from "../../products";

const Header = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
`
const SubHeader = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
`


function DebugModal({open, onClose}: IDebugModal) {
  const answers = useSelector(getStepAnswers)
  const steps = useSelector(getSteps)
  const products = useSelector(getProducts)
  const allProducts = useSelector(getProductsAll)

  const features = steps.reduce((a: any, b: any) => a.concat(b.ansewers.reduce((a:any, b:any) => a.concat(b.features), [])), [])

  const uniqueFeatures = [...new Set(features.map((item:any) => item.name)) as any];

  const customFilters = (answers as any).reduce((a:any,b:any) => a.concat(b.customFilters),[]).filter(Boolean)

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{overflow: "auto", maxHeight:'92%', margin: '20px', backgroundColor:'white', padding: '20px'}}>
        <div style={{marginBottom: '20px'}}>
          <Header>Pytania</Header>
          {(answers as any).map((a: any) => <div style={{marginBottom: '10px'}}><b style={{fontWeight: 'bold'}}>{steps.find((s: any) => s.step === a.step).question}</b>{` ${a.answerVerbose}`}

          {/*<ul style={{marginLeft:'10px'}}>*/}
          {/*  {a.features.map((a: any) => <li>{`${features.find((f:any) => f.id === a).nameVerbose} ${features.find((f: any) => f.id === a).valueVerbose}`}</li>)}*/}
          {/*</ul>*/}

          {/*  {a.customFilters && <ul style={{marginLeft:'10px'}}>*/}
          {/*    {Object.entries(a.customFilters).map(([a, b]: any) => <li>{`${a} ${b}`}</li>)}*/}
          {/*  </ul>}*/}

          </div>)}
        </div>
        <div style={{marginBottom: '20px'}}>
          <Header>Filtry</Header>
          <ul style={{marginLeft:'10px'}}>
            {uniqueFeatures.map((a: any) => features.find((f: any) => f.name === a)).map((f:any) => <li>{`${f.nameVerbose} ${f.valueVerbose}`}</li>)}
            {customFilters.map((c: any) => Object.entries(c).map(([key, value]) => value? <li>{`${key} ${value}`}</li>:<></>))}
          </ul>
        </div>
        <div style={{marginBottom: '20px'}}>
          <Header>Produkty</Header>
          {products && products.map((a: IProduct) => <div style={{marginBottom: '20px'}}>
            <p>ID: {a.id}</p>
            <p>ID sklepu: {a.prestaId}</p>
            <p>Nazwa: {a.name}</p>
            <p>Price: {a.price}</p>
            <p>Ilość: {a.quantity}</p>
            <p>Ilość min: {a.minQuantity}</p>
            <p>Status: {a.state}</p>
            <p>Cechy: <ul style={{marginLeft:'10px'}}>{a.features.map((f: any) => <li>{f.nameVerbose} {f.valueVerbose}</li>)}</ul></p>
            <p>Zgodność: {a.compatibility * 100}%</p>
            <p>Typ oferty: {a.offerType}</p>
          </div>)}
        </div>
        <div style={{marginBottom: '20px'}}>
          <Header>Wszystkie produkty</Header>
          {allProducts && allProducts.map((a: IProduct) => <div style={{marginBottom: '20px'}}>
            <p>ID: {a.id}</p>
            <p>ID sklepu: {a.prestaId}</p>
            <p>Nazwa: {a.name}</p>
            <p>Price: {a.price}</p>
            <p>Ilość: {a.quantity}</p>
            <p>Ilość min: {a.minQuantity}</p>
            <p>Status: {a.state}</p>
            <p>Cechy: <ul style={{marginLeft:'10px'}}>{a.features.map((f: any) => <li>{f.nameVerbose} {f.valueVerbose}</li>)}</ul></p>
            <p>Zgodność: {a.compatibility * 100}%</p>
            <p>Typ oferty: {a.offerType}</p>
          </div>)}
        </div>
      </div>
    </Modal>
  );
};

export interface IDebugModal {
  open: boolean
  onClose: () => void;
}

export default DebugModal