import React from "react";
import styled, { css } from "styled-components";
import { RoundButton } from "../../components/Screens";
import { useDispatch, useSelector } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import { getLoading } from "../../reducers/steps/selectors";
import content from "../../content";
import { ArrowRight } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";

const Wrapper = styled.div`
  padding: 130px 15%;
  @media (max-width: 800px) {
    padding: 70px 10%;
  }
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: black;
`;

const Title = styled.div<ILoading>`
  font-size: 3.5vw;
  margin-top: 100px;
  font-weight: 600;
  @media (max-width: 800px) {
    font-size: 2.5em;
  }
`;

const Content = styled.div<ILoading>`
  font-size: 1.15em;
  color: #717171;
  line-height: 1.5;
  margin: 70px 0px;
`;

function StartScreen() {
  const dispatch = useDispatch();
  const Loading = useSelector(getLoading);

  const onClick = () => dispatch(nextStep("1"));

  const desktop = useMediaQuery("(min-width:800px)");

  return (
    <Wrapper>
      <Title style={desktop ? {} : { marginTop: "20px" }} loading={Loading}>
        {content.getValue("start_title")}
      </Title>
      <Content loading={Loading}>{content.getValue("start_subtitle")}</Content>
      <RoundButton
        onClick={onClick}
        customColor={"black"}
        customBackgroundColor={"#ffbd00"}
      >
        {content.getValue("start_button")}
        <div className={"arrow-div"} style={{ display: "flex", width: "34px" }}>
          <ArrowRight style={{ marginLeft: "5px" }} />
        </div>
      </RoundButton>
    </Wrapper>
  );
}

export interface ILoading {
  loading: boolean;
}

export default StartScreen;
