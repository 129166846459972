import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SwitchTransition } from "react-transition-group";
import Fade from "./Fade";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentStep,
  getLoading,
  getStep,
  getStepAnswers,
} from "../../reducers/steps/selectors";
import { StartScreen, SurveyFinishScreen } from "../Screens";
import StepScreen from "../Screens/StepScreen";
import SummaryBenefitsScreen from "../Screens/SummaryBenefitsScreen";
import { Router } from "./ScreenRouter";
import { StepsActions } from "../../reducers/steps/actionsNames";
import { BenefitsAction } from "../../reducers/benefits/actionsNames";
import { ProductsActions } from "../../reducers/products/ActionsNames";
import nextStep from "../../actions/steps/nextStep";

const Fragment = styled.div`
  width: 50%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.1;
`;

function Screen() {
  const dispatch = useDispatch();
  const Loading = useSelector(getLoading);

  useEffect(() => {
    dispatch({ type: StepsActions.FetchSteps });
    dispatch({ type: BenefitsAction.FetchBenefits });
    dispatch({ type: StepsActions.FetchContent });
    dispatch({ type: StepsActions.FetchVars });
    dispatch({ type: ProductsActions.FetchDelivery });
  }, []);

  const currentStep = useSelector(getCurrentStep);
  const step = useSelector(getStep);

  useEffect(() => {
    if (!Loading) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const order = urlParams.get("order");

      if (order) {
        dispatch({ type: ProductsActions.SetOrderSid, sid: order });
        dispatch(nextStep("paymentWait"));
      }

      if (window.location.pathname.includes("filter_")) {
        dispatch(nextStep("products"));
      }
    }
  }, [Loading]);

  return (
    <Wrapper>
      <SwitchTransition>
        <Fade
          key={currentStep}
          classNames={"fade"}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
        >
          {React.cloneElement(Router[currentStep] as any, { step: step })}
        </Fade>
      </SwitchTransition>
    </Wrapper>
  );
}

export default Screen;
