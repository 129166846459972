import { IStepsStore } from "./index";
import steps from "../../steps";

export default {
  currentStep: "startLoading",
  previousSteps: [],
  steps: [],
  loading: true,
  content: [],
  values: [],
  vars: [],
  stepAnswers: [],
  customValue: 0,
  units: 2,
  period: 4,
  repeat: 1,
} as IStepsStore;
