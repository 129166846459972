import React, {useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import "../../components/Screens/loader.css";
import content from "../../content";
import loadProducts from "../../actions/products/loadProducts";
import {getProducts, getProductsLoading} from "../../reducers/products/selectors";

const pulse = keyframes`
    0% {
      opacity: 0.65;
    }

    70% {
      opacity: 1;
    }

  100% {
    opacity: 0.65;
  }
`;

const SurveyFinishScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 2.5em;
  color: rgb(85, 85, 85);
  transform: scale(1);
  text-align: center;
  animation: ${pulse} 3s infinite;
`;

function SurveyFinishScreen() {
  const dispatch = useDispatch();
  const products = useSelector(getProducts)
  const loading = useSelector(getProductsLoading)

  useEffect(() => {
    if(!loading){
      if(products.length !== 0){
        dispatch(nextStep('products'))
      } else {
        dispatch(nextStep('noProducts'))
      }
    }
  }, [products, loading])

  useEffect(() => {
    dispatch(loadProducts())
  }, []);

  return (
    <SurveyFinishScreenWrapper>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <Label>{content.getValue("loading_title")}</Label>
    </SurveyFinishScreenWrapper>
  );
}

export default SurveyFinishScreen;
