import React, { useEffect, useState } from "react";
import StepStatus, { StatusIconType } from "../../components/Steps/StepStatus";
import { Input } from "../../components/Steps";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import nextStep from "../../actions/steps/nextStep";
import {
  DebugModal,
  ProductsScreenWrapper,
  RoundButton,
} from "../../components/Screens";
import content from "../../content";
import submitPhone from "../../actions/products/submitPhone";
import { isRepeat } from "../../reducers/steps/selectors";
import Button from "@material-ui/core/Button";
import { getParam, getProducts } from "../../reducers/products/selectors";

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
`;

function NoProductsScreen() {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const products = useSelector(getProducts);

  const nextPoint = useSelector(isRepeat);
  const param = useSelector(getParam);

  const onClick = () => {
    dispatch(submitPhone(phone));
    dispatch(nextStep("phoneSubmit"));
  };

  const info = content.getValue(
    param !== "1" ? "products_not_found_info" : "products_not_found_volume_info"
  );
  const onPhoneChange = (e: React.SyntheticEvent<HTMLInputElement>) =>
    setPhone(e.currentTarget.value);

  useEffect(() => {
    if (products.length > 0) {
      dispatch(nextStep("products"));
      return;
    }
  }, [products, param]);

  const Content = (
    <ContentWrapper>
      <Input
        value={phone}
        onChange={onPhoneChange}
        placeholder={content.getValue(
          param !== "1"
            ? "products_not_found_phone_placeholder"
            : "products_not_found_volume_phone_placeholder"
        )}
      />
      <RoundButton
        onClick={onClick}
        disabled={!phone}
        style={{ fontWeight: 500, marginTop: "20px" }}
      >
        {content.getValue(
          param !== "1"
            ? "products_not_found_button"
            : "products_not_found_volume_button"
        )}
      </RoundButton>
    </ContentWrapper>
  );

  const closeModal = () => setOpen(false);

  return (
    <ProductsScreenWrapper>
      {/*<DebugModal open={open} onClose={closeModal} />*/}
      <StepStatus
        iconType={StatusIconType.Failed}
        count={0}
        hide={true}
        Content={Content}
        backStep={"start"}
        withReset={true}
        withNextPoint={nextPoint}
        info={info}
        headerLabel={content.getValue(
          param !== "1"
            ? "products_not_found_header"
            : "products_not_found_volume_header"
        )}
      />
    </ProductsScreenWrapper>
  );
}

export default NoProductsScreen;
