import React from "react";
import styled, { keyframes, css } from "styled-components";
import { IBenefit } from "../../steps";
import Benefit from "./Benefit";
import { useSelector } from "react-redux";
import { getStepBenefits } from "../../reducers/benefits/selectors";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  getStepAnswers,
  getValues,
  getVars,
} from "../../reducers/steps/selectors";
import { isEqual } from "lodash";
import { IStepAnswers } from "../../reducers/steps";
import { State } from "../../reducers/rootReducer";

const SummaryWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  min-height: 200px;
  border-bottom: 1px rgba(255, 255, 255, 0.25) dashed;
`;

const BenefitsWrapper = styled.div`
  font-weight: 300;
  font-size: 0.9em;
  line-height: 1.65;
  width: 100%;
`;

const BenefitsList = styled.ul`
  margin-left: 12%;
  transition: font-weight 0.5s ease-out;
  color: white;
  list-style: disc;
`;

const BenefitsListItem = styled.li<{ new?: boolean; highlighted?: boolean }>`
  font-weight: ${(props) => (props.new ? 500 : 300)};
  color: ${(props) => (props.highlighted ? "wheat" : "white")};
`;

function SummaryBenefits({ label, icon, list }: ISummaryBenefits) {
  const stepBenefits = useSelector(getStepBenefits);
  const values = useSelector(getValues);
  const vars = useSelector(getVars);
  const stepAnswers: IStepAnswers[] = useSelector(getStepAnswers);

  const filterValues = (name: string) => {
    const value = (values as any)
      .reduce((a: any, b: any) => a.concat(b.values), [])
      .find((v: any) => v.name === name);

    const globalValue = vars.find((variable) => variable.name === name);

    if (!value && !globalValue) {
      return {
        ...value,
      };
    }

    if (value && value.value === "" && name === "litry") {
      return {
        ...value,
        value: (stepAnswers as any).find((s: any) => s.values.includes(value))
          ?.liters,
      };
    }

    if (value && value.value === "") {
      return {
        ...value,
        value: stepAnswers.find((s) => s.values.includes(value))?.answerVerbose,
      };
    }

    if (!value && globalValue) {
      return globalValue;
    }

    return value;
  };

  const formatBenefit = (content: string) => {
    if (content.match(new RegExp("%([^%]*)%"))) {
      const newContent = content.replace(
        new RegExp("%([^%]*)%", "g"),
        (value: string) => {
          const newValue = value.replace(
            new RegExp("!(\\w*)", "g"),
            (v: string) => {
              return filterValues(v.substr(1))?.value || v;
            }
          );

          try {
            if (newValue.match(new RegExp("!(\\w*)", "g"))) {
              return "%undefined%";
            }
            var calc = newValue.replace(/,/g, ".").replace(/[^-()\d/*+.]/g, "");

            return isNaN(eval(calc))
              ? eval(calc)
              : Number(eval(calc)).toFixed(2);
          } catch (e) {
            return newValue.slice(1, -1);
          }
        }
      );

      return newContent;
    }
    return content;
  };

  return (
    <SummaryWrapper>
      <Benefit split={true} label={label} icon={icon} />

      <BenefitsWrapper>
        <Label>{label}</Label>
        <BenefitsList key={"benefitList" + label}>
          <TransitionGroup>
            {list
              .sort(
                (a: any, b: any) =>
                  stepBenefits.indexOf(b.id) - stepBenefits.indexOf(a.id)
              )
              .map((el) =>
                el &&
                !formatBenefit(el.content).match(new RegExp("%([^%]*)%")) ? (
                  <CSSTransition
                    key={el.content}
                    timeout={500}
                    classNames={"fade"}
                  >
                    <BenefitsListItem
                      new={stepBenefits.indexOf(el.id) !== -1}
                      highlighted={Boolean(el.highlighted)}
                      key={formatBenefit(el.content)}
                    >
                      {formatBenefit(el.content)}
                    </BenefitsListItem>
                  </CSSTransition>
                ) : (
                  <></>
                )
              )}
          </TransitionGroup>
        </BenefitsList>
      </BenefitsWrapper>
    </SummaryWrapper>
  );
}

const Label = styled.div`
  font-size: 1.5em;
  margin-left: calc(12% - 18px);
  margin-bottom: 15px;
  font-weight: 600;
  color: white;
`;

export default React.memo(SummaryBenefits, (prevProps, nextProps) =>
  isEqual(prevProps.list, nextProps.list)
);
export interface ISummaryBenefits {
  label: string;
  icon: React.ReactNode;
  list: Array<IBenefit | undefined>;
}
