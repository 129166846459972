import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Add, ChevronLeft, ChevronRight, Remove } from "@material-ui/icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  color: black;
`;

const Input = styled.input`
  height: 42px;
  width: 58px;
  border: 1px solid #c9c9c9;
  font-size: 14px;
  text-align: center;
  pointer-events: none;
  color: #080808;
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0);
`;

const Label = styled.label`
  display: flex;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
`;

function NumberInput({ inputLabel, wrapperStyle, ...rest }: ITextInput) {
  const change = (change: number) => () => {
    if (Number(rest.value) + change !== -1) {
      (rest as any).onChange({
        currentTarget: { value: Number(rest.value) + change },
      } as any);
    }
  };
  return (
    <Wrapper style={wrapperStyle} onClick={(e) => e.stopPropagation()}>
      <Remove style={{cursor: 'pointer'}} onClick={change(-1)} />
      {inputLabel && <Label htmlFor={"input-" + rest.name}>{inputLabel}</Label>}
      <Input min={1} step={1} type={"number"} id={"input-" + rest.name} {...rest} />
      <Add style={{cursor: 'pointer'}} onClick={change(1)} />
    </Wrapper>
  );
}

export interface ITextInput extends InputHTMLAttributes<HTMLInputElement> {
  inputLabel?: string;
  wrapperStyle?: React.CSSProperties;
}

export default NumberInput;
