import React, { useEffect, useState } from "react";
import StepStatus, { StatusIconType } from "../../components/Steps/StepStatus";
import { ProductsScreenWrapper, RoundButton } from "../../components/Screens";
import content from "../../content";
import { useSelector } from "react-redux";
import { getOrderId, getOrderSid } from "../../reducers/products/selectors";

function PurchasePaymentSuccessScreen() {
  const [orderId, setOrderId] = useState(0);

  const localOrderId = useSelector(getOrderSid);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/order/sid/" + localOrderId).then(
      async (r) => {
        const resp = await r.json();
        setOrderId(resp["id"]);
      }
    );
  }, []);

  const info = `${content.getValue("payment_success_info")}`;

  return (
    <ProductsScreenWrapper>
      <StepStatus
        iconType={StatusIconType.Success}
        count={0}
        hide={true}
        backStep={"products"}
        backLabel={content.getValue("back_to_shop_button")}
        info={info}
        headerLabel={content.getValue("payment_success_header")}
        label={content.getValue("payment_success_subheder")}
      />
    </ProductsScreenWrapper>
  );
}

export default PurchasePaymentSuccessScreen;
