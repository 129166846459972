import React from "react";
import {Radio} from "@material-ui/core";
import {FormControlLabel, RadioGroup} from "../../components/Steps";
import {IStep} from "./index";
import {IAnswers} from "../../steps";

function Choice({ step, onChange, value }: IStep) {
  function change(answer: IAnswers) {
    return function () {
      onChange(answer);
    };
  }

  return (
    <RadioGroup value={value} name={step.step}>
      {step.answers.map((a,i) => (
        <FormControlLabel
          onChange={change(a)}
          key={a.name}
          value={a.name}
          control={<Radio />}
          label={a.content}
        >
        </FormControlLabel>
      ))}
    </RadioGroup>
  );
}

export default Choice;
