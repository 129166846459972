export enum ProductsActions {
  SelectProduct = "Select product",
  SubmitForm = "Submit form",
  SetPaymentMethod = "Set payment method",
  OrderProduct = "Order product",
  FetchDelivery = "Fetch product delivery",
  InsertDelivery = "Insert product delivery",
  SetOrderId = "Set order id",
  SetOrderSid = "Set order sid",
  SetOrderToken = "Set order payment token",
  SetDelivery = "Set delivery",
  LoadProducts = "Load products",
  SetProducts = "Set products",
  SubmitPhone = "Submit phone",
  RemoveProduct = "Remove product",
  ChangeCount = "Change count",
}
