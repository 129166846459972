import React, { useEffect, useMemo, useState } from "react";
import {
  DebugModal,
  ProductsScreenWrapper,
  RoundButton,
  StepStatusHeader,
} from "../../components/Screens";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getSelectedProductId,
} from "../../reducers/products/selectors";
import { IProduct, ProductOfferType } from "../../products";
import Button from "@material-ui/core/Button";
import UnderlineButton from "../../components/Screens/UnderlineButton";
import content from "../../content";
import nextStep from "../../actions/steps/nextStep";
import resetSteps from "../../actions/steps/resetSteps";
import {
  getPreviousSteps,
  getStepAnswers,
  getSteps,
  getValues,
  getVars,
  isMany,
  isRepeat,
} from "../../reducers/steps/selectors";
import nextWaterPoint from "../../actions/steps/nextWaterPoint";
import { ProductsActions } from "../../reducers/products/ActionsNames";
import { Input } from "../../components/Steps";
import { ContentWrapper } from "./NoProductsScreen";
import submitPhone from "../../actions/products/submitPhone";
import { Info } from "../../components/Steps/StepStatus";
import { NewProduct } from "../Products";
import previousStep from "../../actions/steps/previousStep";
import { Field, Form } from "react-final-form";
import { Row, validateEmail } from "../Purchase/PurchaseForm";
import { ArrowLeft } from "@material-ui/icons";
import { StepsActions } from "../../reducers/steps/actionsNames";
import { BenefitsAction } from "../../reducers/benefits/actionsNames";
import {
  getBenefitsMapped,
  getSummaryBenefits,
} from "../../reducers/benefits/selectors";
import Checkbox from "../../components/Steps/Checkbox";
import { IStepAnswers } from "../../reducers/steps";
import * as Effects from "redux-saga/effects";

const ProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  height: 100%;
  & > div {
    background-color: white;
  }
`;

function ProductsScreen() {
  const [filterHistory, setFilter] = useState({
    customFilters: [],
    filters: [],
    filtered: [],
    param: "0",
  });
  const [phone, setPhone] = useState("");
  const products = useSelector(getProducts);
  const previousSteps = useSelector(getPreviousSteps);
  const [open, setOpen] = useState(false);
  const repeat = useSelector(isRepeat);
  const dispatch = useDispatch();
  const filter = useMemo(() => window.location.pathname.includes("filter_"), [
    window.location.pathname,
  ]);
  const filterActive = useSelector(getStepAnswers).length === 0;

  useEffect(() => {
    if (filter && previousSteps.length <= 3) {
      fetch(
        process.env.REACT_APP_API_URL +
          "/history/" +
          "filter_" +
          window.location.pathname.split("filter_")[1],
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then((r) => r.json())
        .then((data) => {
          setFilter(data);
          dispatch({
            type: ProductsActions.SetProducts,
            products: data.filtered,
            all: [],
          });
          if (data.stepState) {
            dispatch({
              type: StepsActions.SetState,
              stepState: data.stepState,
            });
            dispatch({
              type: BenefitsAction.SetState,
              summaryBenefits: data.summaryBenefits,
              stepBenefits: data.stepBenefits,
            });
          }
        });
    }
  }, [filter]);

  const closeModal = () => setOpen(false);
  const reset = () => {
    dispatch(resetSteps());
    dispatch(nextStep("start"));
  };

  const nextPoint = () => {
    dispatch(nextWaterPoint());
  };

  const onPhoneChange = (e: React.SyntheticEvent<HTMLInputElement>) =>
    setPhone(e.currentTarget.value);

  const onClick = () => {
    dispatch(submitPhone(phone));
    dispatch(nextStep("phoneSubmit"));
  };

  const onClickReset = () => {
    fetch(process.env.REACT_APP_API_URL + "/filter/", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify({
        customFilters: filterHistory.customFilters,
        filters: filterHistory.filters,
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data["filtered"] && data["filtered"].length !== 0) {
          dispatch({
            type: ProductsActions.SetProducts,
            param: data["param"],
            products: data["filtered"],
            all: data["all"],
          });
          window.history.replaceState("", "", `/konfigurator/${data.uid}`);
          setFilter({ ...filterHistory, param: "0" });
        } else {
          dispatch(nextStep("noProducts"));
        }
      });
  };

  const many = useSelector(isMany);
  const selected = useSelector(getSelectedProductId);

  if (!Array.isArray(products)) {
    dispatch(nextStep("noProducts"));
  }

  const Content = () => (
    <ContentWrapper>
      <Input
        value={phone}
        onChange={onPhoneChange}
        placeholder={content.getValue("phone_placeholder")}
      />
      <RoundButton
        onClick={onClick}
        disabled={!phone}
        style={{ fontWeight: 500, marginTop: "20px" }}
      >
        {content.getValue("phone_button")}
      </RoundButton>
    </ContentWrapper>
  );

  const backClick = () => {
    dispatch(previousStep(""));
  };

  const toForm = () => dispatch(nextStep("purchaseData"));

  return (
    <ProductsScreenWrapper style={{ alignItems: "baseline" }}>
      <StepStatusHeader
        filter={filterActive}
        hide={true}
        repeat={repeat}
        count={products.length}
        label={""}
        step={"start"}
        backStep={true}
      />
      {Boolean(process.env.REACT_APP_DEBUG) && (
        <DebugModal open={open} onClose={closeModal} />
      )}
      <ProductsWrapper style={{ height: "unset" }}>
        {products
          .filter((p: IProduct) => p.offerType !== ProductOfferType.Min)
          .map((p: IProduct) => (
            <NewProduct key={p.id} product={p} />
          ))}
        {filterHistory.param !== "0" && filterHistory.param !== undefined && (
          <FilterDialogWrapper>
            <Info
              dangerouslySetInnerHTML={{
                __html: content.getValue(
                  "filter_history_products_not_available_info"
                ),
              }}
            />
            <Content />
            <Label>{content.getValue("or")}</Label>
            <RoundButton
              onClick={onClickReset}
              style={{ fontWeight: 500, marginTop: "20px" }}
            >
              {content.getValue("search_again_button")}
            </RoundButton>
          </FilterDialogWrapper>
        )}
      </ProductsWrapper>
      <OfferEmail />
      <div style={{ display: "flex", marginTop: "25px" }}>
        {!filterActive && (
          <UnderlineButton onClick={backClick}>
            <ArrowLeft style={{ marginRight: "10px" }} />
            {content.getValue("back_button")}
          </UnderlineButton>
        )}
        {!filterActive && (
          <UnderlineButton onClick={reset}>
            {content.getValue("reset")}
          </UnderlineButton>
        )}
        {/*{repeat && <UnderlineButton onClick={nextPoint} style={{placeSelf: "flex-end"}}>*/}
        {/*  {content.getValue("next_point")}*/}
        {/*</UnderlineButton>}*/}
        {many && selected.length > 0 && (
          <UnderlineButton
            onClick={toForm}
            style={{
              fontSize: "0.9em",
              fontWeight: 400,
            }}
          >
            {content.getValue("order_button")}
          </UnderlineButton>
        )}
      </div>
    </ProductsScreenWrapper>
  );
}

export const OfferEmail = () => {
  const benefits = useSelector(getBenefitsMapped);
  const values = useSelector(getValues);
  const vars = useSelector(getVars);
  const stepAnswers: IStepAnswers[] = useSelector(getStepAnswers);
  const steps = useSelector(getSteps);

  const ans = (stepAnswers as any).map((a: any) => ({
    question: steps.find((s: any) => s.step === a.step).question,
    answer: a.answerVerbose,
  }));

  const filterValues = (name: string) => {
    const value = (values as any)
      .reduce((a: any, b: any) => a.concat(b.values), [])
      .find((v: any) => v.name === name);

    const globalValue = vars.find((variable) => variable.name === name);

    if (!value && !globalValue) {
      return {
        ...value,
      };
    }

    if (value && value.value === "" && name === "litry") {
      return {
        ...value,
        value: (stepAnswers as any).find((s: any) => s.values.includes(value))
          ?.liters,
      };
    }

    if (value && value.value === "") {
      return {
        ...value,
        value: stepAnswers.find((s) => s.values.includes(value))?.answerVerbose,
      };
    }

    if (!value && globalValue) {
      return globalValue;
    }

    return value;
  };

  const formatBenefit = (content: string) => {
    if (content.match(new RegExp("%([^%]*)%"))) {
      const newContent = content.replace(
        new RegExp("%([^%]*)%", "g"),
        (value: string) => {
          const newValue = value.replace(
            new RegExp("!(\\w*)", "g"),
            (v: string) => {
              return filterValues(v.substr(1))?.value || v;
            }
          );

          try {
            if (newValue.match(new RegExp("!(\\w*)", "g"))) {
              return "%undefined%";
            }
            var calc = newValue.replace(/,/g, ".").replace(/[^-()\d/*+.]/g, "");

            return isNaN(eval(calc))
              ? eval(calc)
              : Number(eval(calc)).toFixed(2);
          } catch (e) {
            return newValue.slice(1, -1);
          }
        }
      );

      return newContent;
    }
    return content;
  };

  const onSubmit = (values) => {
    if ("email" in values && values.email && validateEmail(values.email)) {
      fetch(process.env.REACT_APP_API_URL + "/history/", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify({
          ...values,
          url: window.location.href,
          answers: ans,
          benefits: benefits
            .map((b) => ({
              ...b,
              content: formatBenefit(b.content),
            }))
            .filter((b) => !b.content.match(new RegExp("%([^%]*)%"))),
        }),
      });
    }
  };

  const required = (value: string) => (value ? undefined : "Wymagane");

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "0.95em",
                  marginTop: "15px",
                  fontWeight: 600,
                  color: "black",
                  marginBottom: "15px",
                }}
              >
                {content.getValue("leave_email_here_info")}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <Field
                    name="email"
                    afterSubmit={
                      function () {
                        // @ts-ignore
                        this.change("");
                      } as any
                    }
                    component={Input}
                    style={{ marginBottom: "0px" }}
                    type={"text"}
                    placeholder={content.getValue("your_email_address")}
                  />
                </div>
                <RoundButton
                  style={{
                    maxHeight: "50px",
                    minHeight: "50px",
                    marginLeft: "30px",
                  }}
                  type={"submit"}
                >
                  {content.getValue("send_offer_email_button")}
                </RoundButton>
              </div>
              <Field
                type="checkbox"
                validate={required}
                name={"agreement"}
                fontSize={"0.9em"}
                fontColor={"rgb(85, 85, 85)"}
                component={Checkbox}
                label={content.getValue("leave_email_here_checkbox")}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const FilterDialogWrapper = styled.section`
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1.1vw;
  font-weight: 600;
  margin: 30px 0px 20px 0px;
`;

export default ProductsScreen;
