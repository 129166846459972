import { State } from "../rootReducer";
import { createSelector } from "reselect";
import { IStep } from "./index";

export const getCurrentStep = (store: State) => store.steps.currentStep;
export const getSteps = (store: State) => store.steps.steps;
export const getStepAnswers = (store: State) => store.steps.stepAnswers;
export const getPreviousSteps = (store: State) => store.steps.previousSteps;
export const getLoading = (store: State) => store.steps.loading;
export const getValues = (store: State) => store.steps.values;
export const getContent = (store: State) => store.steps.content;
export const getRepeat = (store: State) => store.steps.repeat;
export const getStepsState = (store: State) => store.steps;
export const getVars = (store: State) => store.steps.vars;

export const getStep = createSelector(
  [getCurrentStep, getSteps],
  (current, steps) => steps.find((s: IStep) => s.step === current)
);

export const getCurrentStepAnswers = createSelector(
  [getCurrentStep, getStepAnswers],
  (current, steps) => steps.find((s) => s.step === current)
);

export const getOverrideStep = createSelector(
  [getCurrentStep, getStepAnswers],
  (current, steps) =>
    steps[steps.findIndex((s) => !!s.overrideNext)]?.overrideNext
);

export const getStepOneAnwser = createSelector([getStepAnswers], (steps) =>
  steps.find((s) => s.step === "1")
);

export const isRepeat = createSelector(
  [getStepAnswers],
  (steps) => !!steps.find((s) => !!s.repeat)
);
export const isDaily = createSelector(
  [getStepAnswers],
  (steps) => !!steps.find((s) => s.answer === "dailyWaterUsage")
);
export const isMany = createSelector(
  [getStepAnswers],
  (steps) => !!steps.find((s) => s.answer === "manyWaterPoint")
);
