import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./reset.css";
import "./containers/Template/fade.css";
import { StylesProvider } from "@material-ui/core";

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst={true}>
      <App />
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
