import * as Effects from "redux-saga/effects";
import { BenefitsAction } from "../reducers/benefits/actionsNames";
import { IBenefit } from "../reducers/benefits";

function* fetchBenefits() {
  const response = yield fetch(process.env.REACT_APP_API_URL + "/benefit/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  let benefits: IBenefit[] = yield response.json();

  yield Effects.put({
    type: BenefitsAction.InsertBenefits,
    benefits: benefits,
  });
}

export default function* () {
  yield Effects.takeLatest(BenefitsAction.FetchBenefits, fetchBenefits);
}
