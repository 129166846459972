import { IContent } from "./reducers/steps";

class Content {
  content: IContent[] = [];
  lang: string = "pl";

  public setContent(content: IContent[]) {
    this.lang = content[0].lang;
    this.content = content;
  }

  public getValue(slug: string): string {
    return this.content.find((c) => c.slug === slug)?.value || slug;
  }

  public getLang(): string {
    return this.lang;
  }
}

const content = new Content();

export default content;
