import React from "react";
import {
  ProductsScreenWrapper,
  RoundButton,
  StepStatusHeader,
} from "../../components/Screens";
import { useDispatch, useSelector } from "react-redux";
import { isRepeat } from "../../reducers/steps/selectors";
import content from "../../content";
import UnderlineButton from "../../components/Screens/UnderlineButton";
import { ChevronLeft } from "@material-ui/icons";
import nextWaterPoint from "../../actions/steps/nextWaterPoint";
import { IProduct } from "../../products";
import { Product } from "../Products";
import {
  getSelectedProduct,
  getSelectedProductId,
} from "../../reducers/products/selectors";
import styled from "styled-components";
import StepStatus, { StatusIconType } from "../../components/Steps/StepStatus";
import { ContentWrapper } from "./NoProductsScreen";
import nextStep from "../../actions/steps/nextStep";
import { useMediaQuery } from "@material-ui/core";

const ProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  height: 100%;
  & > div:nth-child(even) {
    background-color: rgb(245, 245, 245);
  }
`;

function ProductsCart({}: IProductsCart) {
  const repeat = useSelector(isRepeat);
  const dispatch = useDispatch();
  const desktop = useMediaQuery("(min-width:800px)");

  let products = useSelector(getSelectedProduct);
  products = Array.from(new Set(products.map((a) => a.id))).map((id) => {
    return products.find((a) => a.id === id);
  });

  const toForm = () => dispatch(nextStep("purchaseData"));
  const selected: any = useSelector(getSelectedProductId);

  const nextPoint = () => {
    dispatch(nextWaterPoint());
  };

  const info = "";

  const Content = (
    <ContentWrapper
      style={{
        flexWrap: desktop ? "unset" : "wrap",
        width: desktop ? "50%" : "90%",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <RoundButton
        onClick={nextPoint}
        style={{
          fontWeight: 500,
          marginTop: desktop ? "100px" : "40px",
          padding: desktop ? "0px 50px" : "0px 20px",
        }}
      >
        {content.getValue("next_point_more")}
      </RoundButton>

      <RoundButton
        onClick={toForm}
        style={{
          fontWeight: 500,
          marginTop: desktop ? "100px" : "20px",
          padding: desktop ? "0px 50px" : "0px 20px",
        }}
      >
        {content.getValue("to_form_button")}
      </RoundButton>
    </ContentWrapper>
  );

  return (
    <ProductsScreenWrapper>
      <StepStatus
        iconType={StatusIconType.Cart}
        count={0}
        hide={true}
        Content={Content}
        backStep={"products"}
        backLabel={content.getValue("back_to_shop_button")}
        info={info}
        headerLabel={content.getValue("added_to_card_header")}
        label={content.getValue("added_to_card_subheader")}
      />
    </ProductsScreenWrapper>
  );
}

export interface IProductsCart {}

export default ProductsCart;
