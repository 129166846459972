import * as Effects from 'redux-saga/effects'
import {ProductsActions} from "../reducers/products/ActionsNames";
import {getStepAnswers} from "../reducers/steps/selectors";
import {IStepAnswers} from "../reducers/steps";

function * submitPhone(action: any) {
  const answers:IStepAnswers[] = yield Effects.select(getStepAnswers)
  const filters = answers.reduce((a,b) => a.concat(b.features.map(f => String(f))), [] as string[])

  const body = {
    phone: action.phone,
    filters: filters.map(v => Number(v)),
    answers: answers
  }

  const response = yield fetch(process.env.REACT_APP_API_URL + "/phone/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: JSON.stringify(body)
  });
}

export default function *() {
  yield Effects.takeLatest(ProductsActions.SubmitPhone, submitPhone)
}