import React, { ReactNode } from "react";
import {
  ProductsScreenWrapper,
  StepStatusHeader,
} from "../../components/Screens";
import {
  CheckCircleOutline,
  ChevronLeft,
  HighlightOff,
  AddShoppingCartOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import UnderlineButton from "../Screens/UnderlineButton";
import content from "../../content";
import nextStep from "../../actions/steps/nextStep";
import { useDispatch } from "react-redux";
import resetSteps from "../../actions/steps/resetSteps";
import nextWaterPoint from "../../actions/steps/nextWaterPoint";

const Icon = styled.div`
  margin-top: 100px;
  & > svg {
    font-size: 6em;
    font-weight: 300;
  }
`;

export const Info = styled.span`
  width: 50%;
  font-size: 1.4em;
  line-height: 1.35;
  text-align: center;
  margin: 30px 0px;
`;

const Label = styled.div`
  color: black;
  font-weight: 500;
  font-size: 1.4em;
  margin-top: 50px;
  margin-bottom: 20px;
`;

function StepStatus({
  count,
  headerLabel,
  iconType,
  hide,
  info,
  Content,
  backStep,
  backLabel,
  label,
  withReset,
  withNextPoint,
}: IStepStatus) {
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(resetSteps());
    dispatch(nextStep("start"));
  };

  const nextPoint = () => {
    dispatch(nextWaterPoint());
  };

  return (
    <>
      <StepStatusHeader
        count={count}
        hideAll={hide}
        label={headerLabel}
        step={backStep}
        backLabel={backLabel}
        backStep={withReset}
      />
      {withReset && (
        <UnderlineButton onClick={reset} style={{ placeSelf: "baseline" }}>
          <ChevronLeft />
          {content.getValue("reset")}
        </UnderlineButton>
      )}
      {withNextPoint && (
        <UnderlineButton onClick={nextPoint} style={{ placeSelf: "baseline" }}>
          <ChevronLeft />
          {content.getValue("next_point")}
        </UnderlineButton>
      )}
      <Icon>{iconType}</Icon>
      {label && <Label>{label}</Label>}
      <Info dangerouslySetInnerHTML={{ __html: info }} />
      {Content}
    </>
  );
}

export default StepStatus;
export interface IStepStatus {
  count: number;
  headerLabel: string;
  iconType: StatusIconType;
  info: string;
  Content?: ReactNode;
  backStep: string;
  backLabel?: string;
  hide?: boolean;
  label?: string;
  withReset?: boolean;
  withNextPoint?: boolean;
}

export enum StatusIconType {
  Failed = (<HighlightOff />) as any,
  Success = (<CheckCircleOutline />) as any,
  Cart = (<AddShoppingCartOutlined />) as any,
}
