import * as Effects from 'redux-saga/effects'
import {ProductsActions} from "../reducers/products/ActionsNames";
import {getStepAnswers, getStepsState} from "../reducers/steps/selectors";
import {IStepAnswers} from "../reducers/steps";
import {IProduct, ProductOfferType} from "../products";
import { isEmpty } from 'lodash';
import nextStep from "../actions/steps/nextStep";
import {getStepBenefits, getSummaryBenefits} from "../reducers/benefits/selectors";

function * loadProducts() {
  const anwsers:IStepAnswers[] = yield Effects.select(getStepAnswers)
  const stepState = yield Effects.select(getStepsState)
  const stepBenefits = yield Effects.select(getStepBenefits)
  const summaryBenefits = yield Effects.select(getSummaryBenefits)
  const filters = anwsers.reduce((a,b) => a.concat(b.features.map(f => String(f))), [] as string[])
  const customFilters = anwsers.reduce((a,b) => a.concat(b.customFilters), [])

  const a = anwsers.find(s => s.answer === "waterUsage")
  const param = a?.answerVerbose === '11 osób'

  delete stepState['content']

  const response = yield fetch(process.env.REACT_APP_API_URL + "/filter/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: JSON.stringify({
      customFilters: customFilters.filter(x => !isEmpty(x)),
      filters: filters.map(v => Number(v)),
      steps: stepState,
      stepBenefits: stepBenefits,
      summaryBenefits: summaryBenefits,
      param: param? '1':undefined
    })
  });


  const products:any = yield response.json()

  if(products['filtered'] && products['filtered'].length !== 0){
    yield Effects.put(nextStep('products'))
    window.history.replaceState('', '', `/konfigurator/${products.uid}`)
  } else {
    yield Effects.put(nextStep('noProducts'))
    }

  yield Effects.put({type: ProductsActions.SetProducts, param: products['param'],products: products['filtered'], all: products['all']})
}

export default function * () {
  yield Effects.takeLatest(ProductsActions.LoadProducts, loadProducts)
}