import React, { useState } from "react";
import { Drawer, IconButton, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import Scrollbars from "react-custom-scrollbars";
import content, { default as contentStrings } from "../../content";
import { RoundButton } from "./index";
import { Field, Form, useForm } from "react-final-form";
import { Row } from "../../containers/Purchase/PurchaseForm";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../Steps";
import TextArea from "../Steps/TextArea";
import ld from "lodash";
import { getStepAnswers } from "../../reducers/steps/selectors";

const DrawerWrapper = styled.div`
  padding: 5%;
  width: 50vw;
  @media (max-width: 800px) {
    width: 100vw;
  }
  box-sizing: border-box;
  height: 100vh;
  background-color: rgb(245, 245, 245);
`;

const Header = styled.div`
  display: flex;
  margin-top: 28px;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 2em;
  font-weight: 600;
  font-family: Roboto;
`;

const Content = styled.div`
  margin-top: 80px;
  & > a:link {
    color: (internal value);
    text-decoration: underline;
    cursor: auto;
  }

  & > li {
    display: list-item;
  }
  & > ol {
    display: block;
    list-style-type: decimal;
    margin: 1em 0;
    padding-left: 40px;
  }

  & > ul {
    display: block;
    list-style-type: disc;
    margin: 1em 0;
    padding-left: 40px;
  }

  & > p {
    display: block;
    margin: 1em 0;
  }

  & > b {
    font-weight: bold;
  }
  & > blockquote {
    display: block;
    margin: 1em 40px;
  }
`;

function sendFormData(values) {
  fetch(process.env.REACT_APP_API_URL + "/feedback/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: JSON.stringify(values),
  });
}

function InfoDrawer({ open, onClose, content }: IDrawer) {
  const [form, setForm] = useState(false);
  const [sended, setSended] = useState(false);

  const openContactForm = () => setForm(true);
  const closeContactForm = () => setForm(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    sendFormData(values);
  };

  return (
    <Drawer
      ModalProps={{
        disableEnforceFocus: true,
        BackdropProps: { invisible: true },
      }}
      anchor={"right"}
      keepMounted={false}
      open={open}
      onClose={onClose}
    >
      <Scrollbars
        autoHeight={true}
        autoHeightMax={"100%"}
        autoHeightMin={"100%"}
      >
        <DrawerWrapper>
          <Header>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
            {/*<Label>{contentStrings.getValue("help_header")}</Label>*/}
            {/*<UnderlineButton onClick={onClose}>*/}
            {/*  {contentStrings.getValue("help_collapse")} <ChevronRight />*/}
            {/*</UnderlineButton>*/}
            <RoundButton onClick={openContactForm} style={{ fontSize: "1em" }}>
              {contentStrings.getValue("feedback_button")}
            </RoundButton>
          </Header>
          <Content dangerouslySetInnerHTML={{ __html: content || "" }} />
        </DrawerWrapper>
      </Scrollbars>
      <FormDrawer
        onClose={closeContactForm}
        open={form}
        content={
          <>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) =>
                sended ? (
                  <Label>
                    {contentStrings.getValue("form_feedback_thanks")}
                  </Label>
                ) : (
                  <FormData setSended={setSended} handleSubmit={handleSubmit} />
                )
              }
            </Form>
          </>
        }
      />
    </Drawer>
  );
}

export const ContactDrawer = ({ open, onClose, content }: IDrawer) => {
  const [form, setForm] = useState(false);
  const [sended, setSended] = useState(false);
  const filterActive = useSelector(getStepAnswers).length === 0;
  const openContactForm = () => setForm(true);
  const closeContactForm = () => setForm(false);
  const dispatch = useDispatch();

  const desktop = useMediaQuery("(min-width:800px)");

  const onSubmit = (values) => {
    sendFormData(values);
  };

  if (!desktop && filterActive) {
    return <></>;
  }

  return (
    <Drawer
      ModalProps={{
        disableEnforceFocus: true,
        BackdropProps: { invisible: true },
      }}
      anchor={"right"}
      open={open}
      elevation={filterActive ? 0 : undefined}
      style={{ width: desktop ? "50vw" : "100vw", left: "unset" }}
      hideBackdrop={filterActive}
      disableBackdropClick={filterActive}
      onClose={!filterActive ? onClose : undefined}
    >
      <Scrollbars
        autoHeight={true}
        autoHeightMax={"100%"}
        autoHeightMin={"100%"}
      >
        <DrawerWrapper>
          <Header>
            {!filterActive && (
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            )}
            <div></div>
            <RoundButton onClick={openContactForm} style={{ fontSize: "1em" }}>
              {contentStrings.getValue("feedback_button")}
            </RoundButton>
            {/*<Label>{contentStrings.getValue("contact_info_header")}</Label>*/}
            {/*<UnderlineButton onClick={onClose}>*/}
            {/*  {contentStrings.getValue("help_collapse")} <ChevronRight />*/}
            {/*</UnderlineButton>*/}
          </Header>
          <Content>{content}</Content>
        </DrawerWrapper>
      </Scrollbars>
      <FormDrawer
        onClose={closeContactForm}
        open={form}
        content={
          <>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) =>
                sended ? (
                  <Label>
                    {contentStrings.getValue("form_feedback_thanks")}
                  </Label>
                ) : (
                  <FormData setSended={setSended} handleSubmit={handleSubmit} />
                )
              }
            </Form>
          </>
        }
      />
    </Drawer>
  );
};

function FormDrawer({ open, onClose, content }: IDrawer) {
  return (
    <Drawer
      ModalProps={{
        disableEnforceFocus: true,
        BackdropProps: { invisible: true },
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <Scrollbars
        autoHeight={true}
        autoHeightMax={"100%"}
        autoHeightMin={"100%"}
      >
        <DrawerWrapper>
          <Header>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
            <Label>{contentStrings.getValue("form_feedback_header")}</Label>
            {/*<UnderlineButton onClick={onClose}>*/}
            {/*  {contentStrings.getValue("help_collapse")} <ChevronRight />*/}
            {/*</UnderlineButton>*/}
          </Header>
          <Content>{content}</Content>
        </DrawerWrapper>
      </Scrollbars>
    </Drawer>
  );
}

function FormData(props: any) {
  const required = (value: string) => (value ? undefined : "Wymagane");
  const form = useForm();

  const handleClick = () => {
    form.submit();
    setTimeout(() => {
      props.setSended(true);
    }, 200);
  };

  return (
    <form
      onSubmit={props.handleSubmit}
      style={{ fontFamily: "Roboto", height: "100%" }}
    >
      <Row style={{ marginBottom: "20px", lineHeight: "26px" }}>
        {contentStrings.getValue("form_feedback_info")}
      </Row>
      <Row>
        <Field
          validate={required}
          name="name"
          style={{ marginRight: "10px" }}
          component={Input}
          placeholder={content.getValue("form_feedback_name_placeholder")}
        />
        <Field
          validate={required}
          name="phone"
          style={{ marginLeft: "10px" }}
          component={Input}
          placeholder={content.getValue("form_feedback_phone_placeholder")}
        />
      </Row>
      <Row>
        <Field
          validate={required}
          name="email"
          component={Input}
          placeholder={content.getValue("form_feedback_email_placeholder")}
        />
      </Row>
      <Row>
        <Field
          validate={required}
          name="note"
          rows={6}
          component={TextArea}
          placeholder={content.getValue("form_feedback_note_placeholder")}
        />
      </Row>
      <Row style={{ justifyContent: "flex-end" }}>
        <RoundButton
          disabled={!ld.isEmpty(form.getState().errors)}
          onClick={handleClick}
        >
          {contentStrings.getValue("form_feedback_send")}
        </RoundButton>
      </Row>
    </form>
  );
}

export default InfoDrawer;
export interface IDrawer {
  open: boolean;
  content?: any;
  onClose: () => void;
}
