import { IProductsStore } from "./index";

export default {
  products: [],
  filteredProducts: [],
  loading: true,
  selectedProduct: [],
  form: {
    user: {},
    invoice: {},
    address: {},
    invoiceCheck: false,
    addressCheck: false,
    agreement: false
  },
  cupon: null,
  paymentMethod: 'transfer',
  delivery: []
} as unknown as any;
