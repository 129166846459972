import { AnyAction } from "redux";
import initialStore from "./initialStore";
import { IProduct } from "../../products";
import { ProductsActions } from "./ActionsNames";
import { IPurchaseFormValues } from "../../containers/Purchase/PurchaseForm";
import { StepsActions } from "../steps/actionsNames";

export default (store: IProductsStore = initialStore, action: AnyAction) => {
  switch (action.type) {
    case ProductsActions.SelectProduct:
      return {
        ...store,
        selectedProduct: action.many
          ? [
              ...(store.selectedProduct.find((p) => p.id === action.id)
                ? []
                : store.selectedProduct),
              ...(store.selectedProduct.find((p) => p.id === action.id)
                ? store.selectedProduct.map((p) =>
                    p.id === action.id ? { ...p, count: p.count + 1 } : p
                  )
                : [{ id: action.id, count: 1 }]),
            ]
          : [{ id: action.id, count: 1 }],
      };
    case ProductsActions.RemoveProduct:
      return {
        ...store,
        selectedProduct: [
          ...store.selectedProduct.filter((p) => p.id !== action.id),
        ],
      };
    case ProductsActions.ChangeCount:
      return {
        ...store,
        selectedProduct: [
          ...store.selectedProduct.map((p) =>
            p.id === action.id ? { ...p, count: action.count } : p
          ),
        ],
      };
    case ProductsActions.SetProducts:
      return {
        ...store,
        products: action.products,
        filteredProducts: [
          ...store.filteredProducts.filter(
            (f) => !action.products.find((p) => p.id === f.id)
          ),
          ...action.products,
        ],
        allProducts: action.all,
        loading: false,
        param: action.param,
      };
    case ProductsActions.LoadProducts:
      return {
        ...store,
        loading: true,
        products: [],
      };
    case ProductsActions.SubmitForm:
      return {
        ...store,
        form: action.values,
      };
    case ProductsActions.SetPaymentMethod:
      return {
        ...store,
        paymentMethod: action.value,
      };
    case ProductsActions.InsertDelivery:
      return {
        ...store,
        delivery: action.delivery,
      };
    case ProductsActions.SetOrderId:
      return {
        ...store,
        orderId: action.id,
      };
    case ProductsActions.SetOrderToken:
      return {
        ...store,
        orderToken: action.paymentToken,
      };
    case ProductsActions.SetDelivery:
      return {
        ...store,
        deliveryId: Number(action.value),
      };
    case StepsActions.Reset:
      return {
        ...store,
        selectedProduct: [],
      };
    case StepsActions.PreviousStep:
      return {
        ...store,
        loading: true,
      };
    case "load valid cupon":
      return {
        ...store,
        cupon: action.cupon,
      };
    case ProductsActions.SetOrderSid:
      return {
        ...store,
        orderSid: action.sid,
      };
    default:
      return store;
  }
};

export enum PaymentMethod {
  Partial = "partial",
  Full = "full",
  Transfer = "transfer",
}

export interface IProductsStore {
  loading: boolean;
  selectedProduct: Array<{ id: number; count: number }> | any;
  orderId?: number | undefined;
  products: IProduct[];
  form?: IPurchaseFormValues;
  paymentMethod?: PaymentMethod;
  param?: string;
  delivery: IDelivery[];
  cupon: any;
  orderToken?: string;
  orderSid?: string;
  deliveryId: number;
  allProducts?: IProduct[];
  filteredProducts: IProduct[];
}

export interface IDelivery {
  id: number;
  name: string;
  delay: string;
  active: boolean;
  price: string;
  prestaId: number;
  deleted: boolean;
  position: number;
}
