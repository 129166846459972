import React from "react";
import { SplitScreen } from "../Template/ScreenRouter";
import {Product, Products} from "../Products";
import {useDispatch, useSelector} from "react-redux";
import {getDelivery, getDeliveryId, getSelectedProduct, getSelectedProductId} from "../../reducers/products/selectors";
import {PurchaseDelivery} from "../Purchase";
import order from "../../actions/products/order";

function PurchaseDeliveryScreen() {
  const product = useSelector(getSelectedProduct);
  const products = useSelector(getSelectedProductId);
  const delivery = useSelector(getDelivery)

  if (!products) {
    return <></>;
  }



  return (
    <SplitScreen
        height={true}
      rightScreen={products.length === 1? <Product delivery={true} preview={true} product={product[0]} />: <Products delivery={true}/>}
      leftScreen={<PurchaseDelivery  />}
    />
  );
}

export default PurchaseDeliveryScreen;
