import React from "react";
import styled from "styled-components";
import Benefit from "./Benefit";
import { AttachMoney, AvTimer, Eco } from "@material-ui/icons";
import content from "../../content";

const Info = styled.div`
  font-size: 1.7em;
  line-height: 1.35;
  font-weight: 600;
  color: white;
  margin-top: 100px;
`;

const BenefitWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 75px;
`;

function BenefitsInfo() {
  return (
    <>
      <Info>{content.getValue("benefits_header")}</Info>
      <BenefitWrapper>
        <Benefit
          icon={<AttachMoney />}
          label={content.getValue("benefits_finances")}
        />
        <Benefit icon={<AvTimer />} label={content.getValue("benefits_time")} />
        <Benefit icon={<Eco />} label={content.getValue("benefits_env")} />
      </BenefitWrapper>
    </>
  );
}

export default BenefitsInfo;
