import * as Effects from "redux-saga/effects";
import { StepsActions } from "../reducers/steps/actionsNames";
import { IContent } from "../reducers/steps";
import content from "../content";

function* fetchContent() {
  const response = yield fetch(process.env.REACT_APP_API_URL + "/content/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  let contents: IContent[] = yield response.json();

  content.setContent(contents);

  yield Effects.put({ type: StepsActions.InsertContent, content: contents });
}

export default function* () {
  yield Effects.takeLatest(StepsActions.FetchContent, fetchContent);
}
