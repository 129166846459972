import React, { useEffect, useState } from "react";
import { RoundButton, ScreenWrapper } from "../../components/Screens";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getCupon,
  getDelivery,
  getFormValues,
  getOrderId,
  getOrderToken,
  getPaymentMethod,
  getSelectedProduct,
} from "../../reducers/products/selectors";
import nextStep from "../../actions/steps/nextStep";
import { InfoRow, PurchaseInfoAlert } from "../../components/Purchase";
import { Divider } from "@material-ui/core";
import { IPurchaseFormValues } from "./PurchaseForm";
import UnderlineButton from "../../components/Screens/UnderlineButton";
import { ChevronLeft } from "@material-ui/icons";
import previousStep from "../../actions/steps/previousStep";
import { PaymentMethod } from "../../reducers/products";
import content from "../../content";
import { ProductsActions } from "../../reducers/products/ActionsNames";
import order from "../../actions/products/order";

const InfoHeader = styled.div`
  margin-top: 25px;
  font-size: 1.1em;
  font-weight: 500;
`;

const Header = styled.div`
  font-size: 2.5em;
  margin-bottom: 40px;
  color: rgb(85, 85, 85);
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
`;

function PurchaseInfo({}: IPurchaseInfo) {
  const product = useSelector(getSelectedProduct);
  const paymentMethod = useSelector(getPaymentMethod);
  const delivery = useSelector(getDelivery);
  const dispatch = useDispatch();
  const formValues: IPurchaseFormValues = useSelector(getFormValues);
  const orderId = useSelector(getOrderId);
  const cupon = useSelector(getCupon);
  const token = useSelector(getOrderToken);

  const onClick = () => {
    window.location.href = `https://secure.przelewy24.pl/trnRequest/${token}`;
  };

  const prev = () => dispatch(previousStep("paymentInfo"));

  if (!product) {
    return <></>;
  }

  const header = `${
    content.getValue("payment_summary_header").split("%|%")[0]
  } <span style="color: darkred;">${
    content.getValue("payment_summary_header").split("%|%")[1].split("%1%")[0]
  } ${(product as any)[0].quantity} ${
    content.getValue("payment_summary_header").split("%|%")[1].split("%1%")[1]
  }</span>`;

  const productPrice = product.reduce(
    (a, b) => a + Number(b.price) * b.count * 1.23,
    0
  );
  const price = cupon
    ? productPrice - (productPrice * cupon.percent) / 100 - cupon.amount
    : productPrice;

  return (
    <ScreenWrapper backgroundColor={"white"}>
      <Divider style={{ width: "15%", margin: "40px 0px" }} />
      <Header>{content.getValue("payment_summary")}</Header>
      <InfoContainer>
        <InfoRow
          header={content.getValue("payment_summary_personal_data")}
          rows={[
            formValues.user?.name,
            String(formValues.user?.nip ? formValues.user?.nip : ""),
            `${formValues.user?.street ? formValues.user?.street : ""} ${
              formValues.user?.house && formValues.user?.local
                ? `${formValues.user?.house}/${formValues.user?.local}`
                : formValues.user?.house
                ? formValues.user?.house
                : ""
            }`,
            `${formValues.user?.zipcode ? formValues.user?.zipcode : ""} ${
              formValues.user?.town ? formValues.user?.town : ""
            }`,
          ]}
        />
        {formValues.addressCheck && (
          <InfoRow
            header={content.getValue("payment_summary_address")}
            rows={[
              formValues.address?.name,
              `${
                formValues.address?.street ? formValues.address?.street : ""
              } ${
                formValues.address?.house && formValues.address?.local
                  ? `${formValues.address?.house}/${formValues.address?.local}`
                  : formValues.address?.house
                  ? formValues.address?.house
                  : ""
              }`,
              `${
                formValues.address?.zipcode ? formValues.address?.zipcode : ""
              } ${formValues.address?.town ? formValues.address?.town : ""}`,
            ]}
          />
        )}
        {formValues.invoiceCheck && (
          <InfoRow
            header={content.getValue("payment_summary_invoice")}
            rows={[
              formValues.invoice?.name,
              String(formValues.invoice?.nip ? formValues.invoice?.nip : ""),
              `${
                formValues.invoice?.street ? formValues.invoice?.street : ""
              } ${
                formValues.invoice?.house && formValues.invoice?.local
                  ? `${formValues.invoice?.house}/${formValues.invoice?.local}`
                  : formValues.invoice?.house
                  ? formValues.invoice?.house
                  : ""
              }`,
              `${
                formValues.invoice?.zipcode ? formValues.invoice?.zipcode : ""
              } ${formValues.invoice?.town ? formValues.invoice?.town : ""}`,
            ]}
          />
        )}
        <InfoRow
          header={content.getValue("payment_summary_method")}
          rows={[
            `${
              delivery.name.includes("pobranie")
                ? content.getValue("payment_onplace")
                : content.getValue("payment_transfer")
            } (${(
              product.reduce((a, b) => a + Number(b.price) * b.count, 0) * 1.23
            ).toFixed(2)} ${content.getValue("products_price")})`,
            !cupon
              ? ""
              : `${content.getValue("discount")} ${
                  cupon.cupon
                } (${content.getValue("discount_price")} ${(
                  productPrice - price
                ).toFixed(2)} ${content.getValue("products_price")})`,
            `${delivery.name} (${
              delivery.price ? Number(delivery.price).toFixed(2) : ""
            } ${content.getValue("products_price")})`,
            `${content.getValue("full_price")} ${(
              price + Number(delivery.price)
            ).toFixed(2)} ${content.getValue("products_price")}`,
          ]}
        />
      </InfoContainer>

      <InfoHeader dangerouslySetInnerHTML={{ __html: header }} />
      {!delivery.name.includes("pobranie") && (
        <RoundButton
          onClick={onClick}
          style={{ margin: "30px 0px", padding: "0px 50px", fontWeight: 500 }}
        >
          {content.getValue("payment_summary_button")}
        </RoundButton>
      )}
      <PurchaseInfoAlert />
      {/*<div*/}
      {/*  style={{*/}
      {/*    marginTop: "25px",*/}
      {/*    fontSize: "1.2em",*/}
      {/*    fontWeight: 600,*/}
      {/*    marginBottom: "10px",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div>{content.getValue("payment_bank")}</div>*/}
      {/*  <div>{content.getValue("payment_address")}</div>*/}
      {/*  <div>*/}
      {/*    {content.getValue("payment_title")} {orderId}*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    {content.getValue("payment_price")}{" "}*/}
      {/*    {(price + Number(delivery.price)).toFixed(2)}{" "}*/}
      {/*    {content.getValue("products_price")}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div>
        {/*<UnderlineButton onClick={prev}>*/}
        {/*  <ChevronLeft />*/}
        {/*  {content.getValue("back_button")}*/}
        {/*</UnderlineButton>*/}
      </div>
    </ScreenWrapper>
  );
}

export interface IPurchaseInfo {}

export default PurchaseInfo;
