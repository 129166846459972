import React, {useState} from "react";
import { IStep } from ".";
import { Input } from "../../components/Steps";
import { IAnswers } from "../../steps";
import {useSelector} from "react-redux";
import { isDaily } from "../../reducers/steps/selectors";
import {addRange, mapLitresToRange} from "../../components/Steps/Custom/CustomStep4";

function Answer({ step, onChange, value }: IAnswer) {
  const daily = useSelector(isDaily)
  const [added, setAdded] = useState<boolean>(daily && !!value);


  function change(answer: IAnswers) {
    return function (e: React.SyntheticEvent<HTMLInputElement>) {
      onChange(answer, e.currentTarget.value);
      setAdded(false)
    };
  }

  const onblur = (answer: IAnswers) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    if(step.step === '11' || step.step === '12'){
      let customFilters:any = {}
      let value1 = Number(e.currentTarget.value)
      if(daily){
        value1 = value1 + 50
        setAdded(true)
      }
      customFilters['Średnie zapotrzebowanie dla:'] = mapLitresToRange(Number(value1))
      customFilters['(Max) Średnie zapotrzebowanie dla:'] = addRange(mapLitresToRange(Number(value1)))
      onChange({...answer, customFilters}, String(value1));
    }
  }

  return (
    <>
      {step.answers.map((a,i) => (<>
        <Input
          type={'number'}
          value={added? (Number(value)-50).toString():value}
          onBlur={onblur(a)}
          onChange={change(a)}
          key={a.name}
          placeholder={a.content}
        />
        {process.env.REACT_APP_DEBUG && <div style={{marginTop: '-20px', marginBottom: '20px'}}>{step.ansewers[i].features.map((f: any) => <p>{f.nameVerbose} {f.valueVerbose}</p>)}</div>}
      </>))}
    </>
  );
}

export default Answer;
export interface IAnswer extends IStep {
  value?: string;
}
