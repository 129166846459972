import React, { useState } from "react";
import { Field, Form, useField, useForm } from "react-final-form";
import { Input } from "../../components/Steps";
import styled from "styled-components";
import {
  RoundButton,
  ScreenWrapper,
  StepControls,
} from "../../components/Screens";
import { Divider, useMediaQuery } from "@material-ui/core";
import Checkbox from "../../components/Steps/Checkbox";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import submitForm from "../../actions/products/submitForm";
import { getFormValues } from "../../reducers/products/selectors";
import content from "../../content";
import ld from "lodash";

export const InputRow = styled.div`
  display: flex;
  & > input:first-child {
    width: 55%;
    min-width: 55%;
    @media (max-width: 800px) {
      width: 100%;
      min-width: 100%;
    }
  }
  & > div {
    width: 55%;
    min-width: 55%;
    @media (max-width: 800px) {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const InputInfo = styled.label`
  font-size: 1em;
  color: rgb(85, 85, 85);
  padding-left: 10px;
  margin-right: -50px;
  @media (max-width: 800px) {
    padding-left: 0px;
    margin: 0px 0px 10px 0px;
  }
`;

export const Row = styled.div`
  display: flex;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: rgb(85, 85, 85);
`;

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function FormData(props: any) {
  const differentAddress = useField("addressCheck");
  const invoice = useField("invoiceCheck");
  const form = useForm();
  const desktop = useMediaQuery("(min-width:800px)");
  const required = (value: string) => (value ? undefined : "Wymagane");

  const validEmail = (value: string) =>
    validateEmail(value) ? undefined : "Wprowadź poprawny email";

  const [cupon, setCupon] = useState("");
  const [cuponError, setCuponError] = useState("");
  const [cuponSuccess, setCuponSuccess] = useState("");

  const dispatch = useDispatch();

  const verifyCupon = () => {
    fetch(process.env.REACT_APP_API_URL + "/cupon/", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify({ code: cupon }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data && data["cupon"]) {
          dispatch({ type: "load valid cupon", cupon: data });
          setCuponSuccess(content.getValue("form_cupon_sucess"));
        } else {
          throw Error();
        }
      })
      .catch((reason) => {
        dispatch({ type: "load valid cupon", cupon: null });
        setCuponError(content.getValue("form_cupon_error"));
      });
  };
  const onCuponChange = (e) => {
    setCuponError("");
    setCuponSuccess("");
    setCupon(e.currentTarget.value);
  };

  return (
    <form onSubmit={props.handleSubmit} style={{ height: "100%" }}>
      <div style={{ paddingRight: "80px" }}>
        <Divider
          style={{ width: "15%", margin: desktop ? "20px 0px" : "20px 0px" }}
        />
        <Header>{content.getValue("form_personal_data_header")}</Header>
        <InputRow>
          <Field
            validate={required}
            name="user.name"
            component={Input}
            error={"we"}
            placeholder={content.getValue(
              "form_personal_data_name_placeholder"
            )}
          />
        </InputRow>
        <InputRow style={desktop ? {} : { flexDirection: "column" }}>
          <Field
            name="user.nip"
            component={Input}
            placeholder={content.getValue("form_personal_data_nip_placeholder")}
          />
          <InputInfo>
            {content.getValue("form_personal_data_nip_info")}
          </InputInfo>
        </InputRow>
        <InputRow>
          <Field
            validate={validEmail}
            name="user.email"
            component={Input}
            placeholder={content.getValue(
              "form_personal_data_email_placeholder"
            )}
          />
        </InputRow>
        <InputRow>
          <Field
            validate={required}
            name="user.phone"
            component={Input}
            placeholder={content.getValue(
              "form_personal_data_phone_placeholder"
            )}
          />
        </InputRow>
        <InputRow>
          <Field
            validate={required}
            name="user.street"
            component={Input}
            placeholder={content.getValue(
              "form_personal_data_street_placeholder"
            )}
          />
        </InputRow>
        <InputRow>
          <Row>
            <Field
              validate={required}
              name={"user.house"}
              error={true}
              component={Input}
              placeholder={content.getValue(
                "form_personal_data_house_placeholder"
              )}
              style={{ marginRight: "20px" }}
            />
            <Field
              name="user.local"
              component={Input}
              placeholder={content.getValue(
                "form_personal_data_local_placeholder"
              )}
            />
          </Row>
        </InputRow>
        <InputRow>
          <Row>
            <Field
              validate={required}
              name="user.zipcode"
              component={Input}
              placeholder={content.getValue(
                "form_personal_data_zip_placeholder"
              )}
              style={{ marginRight: "20px" }}
            />
            <Field
              validate={required}
              name="user.town"
              component={Input}
              placeholder={content.getValue(
                "form_personal_data_town_placeholder"
              )}
            />
          </Row>
        </InputRow>
        <Column>
          <Field
            type="checkbox"
            fontSize={"1em"}
            name={"addressCheck"}
            component={Checkbox}
            label={content.getValue("form_different_address_checkbox")}
          />
          {differentAddress.input.value && (
            <>
              <Divider style={{ width: "15%", margin: "40px 0px" }} />
              <Header>
                {content.getValue("form_different_address_header")}
              </Header>
              <InputRow>
                <Field
                  validate={required}
                  name="address.name"
                  component={Input}
                  placeholder={content.getValue(
                    "form_different_address_name_placeholder"
                  )}
                />
              </InputRow>
              <InputRow>
                <Field
                  name="address.company"
                  component={Input}
                  placeholder={content.getValue(
                    "form_different_address_company_placeholder"
                  )}
                />
              </InputRow>
              <InputRow>
                <Field
                  validate={required}
                  name="address.street"
                  component={Input}
                  placeholder={content.getValue(
                    "form_different_address_street_placeholder"
                  )}
                />
              </InputRow>
              <InputRow>
                <Row>
                  <Field
                    validate={required}
                    name="address.house"
                    component={Input}
                    placeholder={content.getValue(
                      "form_different_address_house_placeholder"
                    )}
                    style={{ marginRight: "20px" }}
                  />
                  <Field
                    name="address.local"
                    component={Input}
                    placeholder={content.getValue(
                      "form_different_address_local_placeholder"
                    )}
                  />
                </Row>
              </InputRow>
              <InputRow>
                <Row>
                  <Field
                    validate={required}
                    name="address.zipcode"
                    component={Input}
                    placeholder={content.getValue(
                      "form_different_address_zip_placeholder"
                    )}
                    style={{ marginRight: "20px" }}
                  />
                  <Field
                    validate={required}
                    name="address.town"
                    component={Input}
                    placeholder={content.getValue(
                      "form_different_address_town_placeholder"
                    )}
                  />
                </Row>
              </InputRow>
            </>
          )}
          <Field
            type="checkbox"
            name={"invoiceCheck"}
            fontSize={"1em"}
            component={Checkbox}
            label={content.getValue("form_invoice_checkbox")}
          />
          {invoice.input.value && (
            <>
              <Divider style={{ width: "15%", margin: "40px 0px" }} />
              <Header>{content.getValue("form_invoice_header")}</Header>
              <InputRow>
                <Field
                  validate={required}
                  name="invoice.name"
                  component={Input}
                  placeholder={content.getValue(
                    "form_invoice_company_name_placeholder"
                  )}
                />
              </InputRow>
              <InputRow>
                <Field
                  validate={required}
                  name="invoice.nip"
                  component={Input}
                  placeholder={content.getValue("form_invoice_nip_placeholder")}
                />
              </InputRow>
              <InputRow>
                <Field
                  validate={required}
                  name="invoice.street"
                  component={Input}
                  placeholder={content.getValue(
                    "form_invoice_street_placeholder"
                  )}
                />
              </InputRow>
              <InputRow>
                <Row>
                  <Field
                    validate={required}
                    name="invoice.house"
                    component={Input}
                    placeholder={content.getValue(
                      "form_invoice_house_placeholder"
                    )}
                    style={{ marginRight: "20px" }}
                  />
                  <Field
                    name="invoice.local"
                    component={Input}
                    placeholder={content.getValue(
                      "form_invoice_local_placeholder"
                    )}
                  />
                </Row>
              </InputRow>
              <InputRow>
                <Row>
                  <Field
                    validate={required}
                    name="invoice.zipcode"
                    component={Input}
                    placeholder={content.getValue(
                      "form_invoice_zip_placeholder"
                    )}
                    style={{ marginRight: "20px" }}
                  />
                  <Field
                    validate={required}
                    name="invoice.town"
                    component={Input}
                    placeholder={content.getValue(
                      "form_invoice_town_placeholder"
                    )}
                  />
                </Row>
              </InputRow>
            </>
          )}
        </Column>
        <Divider style={{ width: "15%", margin: "20px 0px" }} />
        <InputRow
          style={{
            flexDirection: desktop ? "row" : "column",
          }}
        >
          <Input
            placeholder={content.getValue("form_cupon_code")}
            value={cupon}
            onChange={onCuponChange}
          />
          <RoundButton
            style={{ maxHeight: "50px", marginLeft: "25px" }}
            title={content.getValue("form_cupon_verify_button")}
            disabled={!cupon}
            onClick={verifyCupon}
          >
            {content.getValue("form_cupon_verify_button")}
          </RoundButton>
        </InputRow>
        {cuponError && <span style={{ color: "darkred" }}>{cuponError}</span>}
        {cuponSuccess && <span style={{ color: "green" }}>{cuponSuccess}</span>}
        <Divider style={{ width: "15%", margin: "20px 0px" }} />
        <Field
          type="checkbox"
          validate={required}
          name={"agreement"}
          fontSize={"1em"}
          component={Checkbox}
          label={
            <>
              {content.getValue("form_agreement_checkbox")}{" "}
              <a
                target={"_blank"}
                href={
                  "https://proecosolar.eu/pl/content/43-polityka-prywatnosci"
                }
              >
                {content.getValue("privacy_policy")}
              </a>
            </>
          }
        />
        <div style={{ marginTop: "20px" }} />
        <Field
          type="checkbox"
          validate={required}
          name={"agreement_regulations"}
          fontSize={"1em"}
          component={Checkbox}
          label={
            <>
              {content.getValue("form_agreement_regulations")}{" "}
              <a
                target={"_blank"}
                href={"https://proecosolar.eu/pl/content/3-regulamin"}
              >
                {content.getValue("regulations")}
              </a>
            </>
          }
        />
        <div style={{ marginTop: "20px" }} />
        <Field
          type="checkbox"
          validate={required}
          name={"agreement_resignation"}
          fontSize={"1em"}
          component={Checkbox}
          label={
            <>
              {content.getValue("form_agreement_resignation")}{" "}
              <a
                target={"_blank"}
                href={
                  "https://proecosolar.eu/pl/content/40-formularz-odstapienia-od-umowy?content_only=1"
                }
              >
                {content.getValue("resignation_link")}
              </a>{" "}
              {content.getValue("form_agreement_resignation_2")}
            </>
          }
        />
        <StepControls
          next={"purchaseDelivery"}
          thisStep={"purchaseData"}
          block={!ld.isEmpty(form.getState().errors)}
        />
      </div>
    </form>
  );
}

function PurchaseForm({}: IPurchaseForm) {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues);

  const onSubmit = (values: IPurchaseFormValues) => {
    dispatch(submitForm(values));
  };

  return (
    <ScreenWrapper
      style={{ paddingBottom: "20px", paddingRight: "0px" }}
      backgroundColor={"white"}
    >
      <Form onSubmit={onSubmit} initialValues={formValues}>
        {({ handleSubmit }) => <FormData handleSubmit={handleSubmit} />}
      </Form>
    </ScreenWrapper>
  );
}

export interface IPurchaseForm {}

export default PurchaseForm;
export interface IPurchaseFormValues {
  user: {
    name: string;
    nip: number;
    email: string;
    phone: string;
    street: string;
    house: string;
    local: string;
    zipcode: string;
    town: string;
  };
  address: {
    name: string;
    company: string;
    street: string;
    house: string;
    local: string;
    zipcode: string;
    town: string;
  };
  invoice: {
    name: string;
    nip: number;
    street: string;
    house: string;
    local: string;
    zipcode: string;
    town: string;
  };
  cupon: string;
  addressCheck: boolean;
  invoiceCheck: boolean;
  agreement: boolean;
}
