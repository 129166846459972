import React from 'react'
import {Info} from "@material-ui/icons";
import content from "../../content";

function StepInfoAssociation({}: IStepInfoAssociation) {
 return (
  <div style={{
    margin: '40px 0px 10px 0px',
    display: 'flex', alignItems: 'center'}}>
   <Info fontSize={"large"}/>
   <div style={{
     marginLeft: '20px',
     fontWeight: "bold"
   }}>{content.getValue('step_info_association')}</div>
  </div>
 );
};

export interface IStepInfoAssociation {

}

export default StepInfoAssociation