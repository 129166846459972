import { IValue } from "./reducers/steps";

export interface IAnswers {
  id: number;
  name: string;
  content: string;
  nextStep: string;
  overrideNextStep: string;
  benefits: number[];
  features: number[];
  values: IValue[];
  customFilters: any;
  repeat: number;
  liters?: number;
}

export enum QuestionType {
  YesNo,
  Choice,
  Answer,
  Custom = 4,
}

export enum BenefitType {
  Eco,
  Funds,
  Time,
}

export interface IStep {
  step: string;
  nextStep: string;
  type: QuestionType;
  question: string;
  subQuestion?: string;
  answers: IAnswers[];
  ansewers: any[];
  progression: number;
  info: string;
  customFields?: ICustomField[];
}

export interface ICustomField {
  content: string;
  value: number;
}

export interface IBenefit {
  id: number;
  type: BenefitType;
  content: string;
  highlighted?: number;
}

export const benefits: IBenefit[] = [
  {
    id: 1,
    type: BenefitType.Eco,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    type: BenefitType.Funds,
    content: "Cras at elit eget metus ornare interdum",
  },
  {
    id: 3,
    type: BenefitType.Funds,
    content:
      "Vestibulum risus ligula, ornare sit amet semper id, viverra a enim",
  },
  {
    id: 4,
    type: BenefitType.Eco,
    content: "Quisque dignissim imperdiet ornare",
  },
  {
    id: 5,
    type: BenefitType.Time,
    content: "Donec ante lorem, molestie ac purus vel, tempus rhoncus purus",
  },
  { id: 6, type: BenefitType.Eco, content: "Quisque eu massa diam" },
  {
    id: 7,
    type: BenefitType.Funds,
    content: "Aliquam maximus eros at iaculis varius",
  },
  {
    id: 8,
    type: BenefitType.Time,
    content:
      "Duis leo nibh, ultrices non facilisis sed, scelerisque sit amet turpis",
  },
];

export default [
  {
    step: "1",
    progression: 0,
    type: QuestionType.Choice,
    question: "Wybierz swoje aktualne źródło podgrzewania wody:",
    info:
      "Curabitur eget nisl lorem. Quisque tincidunt imperdiet feugiat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent maximus magna et quam rhoncus commodo. Nullam fringilla mattis quam, quis accumsan sapien euismod eget. In finibus a ante et malesuada. Nulla mauris ligula, suscipit vitae consectetur et, pellentesque id lacus. Vivamus nibh dui, sagittis ac bibendum varius, placerat eget magna. Sed ut justo in dolor sodales tempor. Curabitur eget ultrices felis, sed consectetur magna.",
    answers: [
      {
        name: "noWarmWater",
        content: "Nie posiadam obecnie ciepłej wody",
        nextStep: "2",
        benefits: [1, 2],
      },
      {
        name: "gasHeating",
        content: "Ogrzewanie na gaz",
        nextStep: "2",
        benefits: [3],
      },
      {
        name: "oilHeating",
        content: "Ogrzewanie na olej opałowy",
        nextStep: "2",
        benefits: [3],
      },
      {
        name: "coalHeating",
        content: "Ogrzewanie na węgiel",
        nextStep: "2",
        benefits: [3],
      },
      {
        name: "woodHeating",
        content: "Ogrzewanie na drewno",
        nextStep: "2",
        benefits: [3],
      },
      {
        name: "ecoHeating",
        content: "Ogrzewanie na ekogroszek",
        nextStep: "2",
        benefits: [3],
      },
      {
        name: "electricityHeating",
        content: "Ogrzewanie na prąd",
        nextStep: "2",
        benefits: [3],
      },
    ],
  },
  {
    step: "2",
    progression: 16,
    type: QuestionType.Answer,
    question: "Wpisz ile wynosi twoje miesięczne zużycie wody:",
    info:
      "Donec bibendum pellentesque nulla bibendum posuere. Curabitur cursus, quam luctus pellentesque convallis, magna magna ultrices odio, et euismod erat lorem eget nisl. Donec id orci eget magna convallis vulputate. Mauris porttitor faucibus euismod. Phasellus lorem purus, pulvinar nec suscipit sagittis, blandit eu sapien. Nullam feugiat volutpat dui at scelerisque. Mauris tortor lacus, bibendum ac elit in, sodales lacinia dolor. Donec eleifend leo et libero pellentesque, pretium sagittis turpis posuere. Nulla in mauris mattis ipsum lobortis eleifend non sed nibh. Praesent ornare, ante consectetur varius efficitur, odio velit sollicitudin tellus, imperdiet tempus nisi augue ut dolor. Praesent vulputate, lorem at commodo venenatis, massa mauris malesuada velit, a porttitor ipsum massa in felis. Vivamus placerat, dui sed euismod vulputate, felis massa fringilla justo, vel tincidunt mauris orci sit amet lectus. Ut luctus mauris a risus malesuada ultricies. Nullam tristique ipsum mauris, sit amet lobortis orci convallis eget. Vivamus pellentesque sit amet nulla ut pellentesque.",
    answers: [
      {
        name: "waterUsage",
        content: "Wprowadź przybliżone zużycie wody",
        nextStep: "3",
        benefits: [4],
      },
    ],
  },
  {
    step: "3",
    progression: 32,
    type: QuestionType.Choice,
    info:
      "Sed ultrices ornare lacinia. Duis porta sit amet augue fringilla vestibulum. In ante ipsum, fringilla at mollis id, eleifend ac dui. Sed quis vestibulum nulla, et consectetur tellus. Integer tincidunt velit at euismod aliquam. Fusce maximus arcu risus. Donec eget egestas ligula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac turpis eu urna lacinia ornare non sit amet neque. Morbi dapibus massa sed elit volutpat, sed scelerisque justo sodales. Duis volutpat posuere ex, sit amet suscipit orci sagittis ac. Nulla sodales magna turpis, vitae cursus odio sollicitudin eget. Nam at ultricies nibh. Praesent vel dapibus quam. Proin porttitor turpis lorem, id ornare sem interdum nec.",
    question: "Wybierz jakie będzie przeznaczenie urządzenia:",
    answers: [
      {
        name: "dailyUsage",
        content: "Codzienny użytek w miejscu zamieszkania",
        nextStep: "4",
        benefits: [8],
      },
      {
        name: "commercialUsage",
        content: "Użycie komercyjne (np. agroturystyka, zakład pracy)",
        nextStep: "finish",
        benefits: [8],
      },
      {
        name: "plotUsage",
        content: "Użytek na działce",
        nextStep: "7",
        benefits: [8],
      },
    ],
  },
  {
    step: "4",
    progression: 50,
    type: QuestionType.Choice,
    info: "",
    question: "Określ swoje miejsce zamieszkania:",
    answers: [
      {
        name: "house",
        content: "Dom wolnostojący",
        nextStep: "5",
        benefits: [8],
      },
      {
        name: "twinHouse",
        content: "Dom wielorodzinny",
        nextStep: "5",
        benefits: [8],
      },
      {
        name: "flat",
        content: "Mieszkanie w bloku (gęsta zabudowa miejska)",
        nextStep: "6",
        benefits: [8],
      },
    ],
  },
  {
    step: "5",
    progression: 75,
    type: QuestionType.YesNo,
    info: "",
    question:
      "Czy posiadasz obok domu wolną i nasłonecznioną przez większość dnia przestrzeń?",
    subQuestion: "(maksymalna odległość urządzenia od mieszkania wynosi 15m)",
    answers: [
      {
        name: "freeSpace",
        content: "TAK",
        nextStep: "finish",
        benefits: [1, 2],
      },
      { name: "noFreeSpace", content: "NIE", nextStep: "6", benefits: [3] },
    ],
  },
  {
    step: "6",
    progression: 90,
    type: QuestionType.YesNo,
    info: "",
    question:
      "Czy masz możliwość zamontowania urządzenia na dachu budynku, który przez większość dnia jest nasłoneczniony?",
    answers: [
      { name: "roof", content: "TAK", nextStep: "finish", benefits: [1, 2] },
      { name: "noRoof", content: "NIE", nextStep: "finish", benefits: [3] },
    ],
  },
  {
    step: "7",
    progression: 60,
    type: QuestionType.YesNo,
    info: "",
    question: "Czy w twojej lokalizacji znajduje się dostęp do wody bieżącej?",
    answers: [
      { name: "runningWater", content: "TAK", nextStep: "8", benefits: [1, 2] },
      { name: "noRunningWater", content: "NIE", nextStep: "9", benefits: [3] },
    ],
  },
  {
    step: "8",
    progression: 70,
    type: QuestionType.YesNo,
    info: "",
    question: "Czy potrzebujesz ciepłej wody pod ciśnieniem?",
    answers: [
      {
        name: "pressurizedWater",
        content: "TAK",
        nextStep: "9",
        benefits: [1, 2],
      },
      {
        name: "noPressurizedWater",
        content: "NIE",
        nextStep: "9",
        benefits: [3],
      },
    ],
  },
  {
    step: "9",
    progression: 80,
    type: QuestionType.Answer,
    info: "",
    question: "Wpisz ile litrów ciepłej wody będziesz zużywał:",
    answers: [
      {
        name: "warmWaterUsage",
        content: "Wprowadź przybliżone zużycie wody",
        nextStep: "10",
        benefits: [1, 2],
      },
    ],
  },
  {
    step: "10",
    progression: 90,
    type: QuestionType.Choice,
    info: "",
    question: "Wybierz jaka będzie częstotliwość używania wody:",
    answers: [
      {
        name: "waterUsageFrequencyDaily",
        content: "Dzień po dniu",
        nextStep: "finish",
        benefits: [1, 2],
      },
      {
        name: "waterUsageFrequencyLess",
        content: "Co 2-3 dni (lub rzadziej)",
        nextStep: "finish",
        benefits: [1, 2],
      },
    ],
  },
] as IStep[];
