import {
  NoProductsScreen,
  PhoneSubmitScreen,
  ProductsCart,
  ProductsScreen,
  PurchaseDataScreen,
  PurchaseDeliveryScreen,
  PurchasePaymentFailedScreen,
  PurchasePaymentSuccessScreen,
  StartLoading,
  StartScreen,
  PaymentWait,
  SurveyFinishScreen,
} from "../Screens";
import React from "react";
import styled from "styled-components";
import StepScreen from "../Screens/StepScreen";
import SummaryBenefitsScreen from "../Screens/SummaryBenefitsScreen";
import { IStep } from "../Steps";
import PurchasePaymentScreen from "../Screens/PurchasePaymentScreen";
import PurchaseInfoScreen from "../Screens/PurchaseInfoScreen";
import FakePayment from "../Screens/FakePayment";
import { useMediaQuery } from "@material-ui/core";
import logo from "../../assets/logo.svg";
import { ProductsScreenWrapper } from "../../components/Screens";
import Scrollbars from "react-custom-scrollbars";

const Fragment = styled.div<{ height?: boolean; desktop?: boolean }>`
  width: ${({ desktop }) => (desktop ? "50%" : "100%")};
  @media (max-width: 800px) {
    height: ${({ height }) => (height ? "unset" : "100%")};
    min-height: 100%;
  }
`;

export const SplitScreen = ({
  leftScreen,
  rightScreen,
  height,
  scroll = true,
  step = undefined,
}: ISplitScreen) => {
  const desktop = useMediaQuery("(min-width:800px)");
  return desktop ? (
    scroll ? (
      <>
        <Scrollbars
          autoHeight={true}
          autoHide={true}
          autoHeightMin={"100%"}
          renderTrackHorizontal={() => <div style={{ display: "none" }} />}
        >
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              minHeight: "100vh",
            }}
          >
            <Fragment
              height={height}
              style={{ backgroundColor: "#f5f8fc" }}
              desktop={desktop}
            >
              {React.cloneElement(leftScreen, { step: step })}
            </Fragment>
            <Fragment
              height={height}
              style={{ backgroundColor: "#00a04f" }}
              desktop={desktop}
            >
              {rightScreen}
            </Fragment>
          </div>
        </Scrollbars>
      </>
    ) : (
      <>
        <Fragment
          height={height}
          style={{ backgroundColor: "#f5f8fc" }}
          desktop={desktop}
        >
          {React.cloneElement(leftScreen, { step: step })}
        </Fragment>
        <Fragment
          height={height}
          style={{ backgroundColor: "#00a04f" }}
          desktop={desktop}
        >
          {rightScreen}
        </Fragment>
      </>
    )
  ) : (
    <div style={{ width: "100%" }}>
      <Fragment
        height={height}
        style={{ backgroundColor: "#f5f8fc" }}
        desktop={desktop}
      >
        {React.cloneElement(leftScreen, { step: step })}
      </Fragment>
      <Fragment
        height={height}
        style={{ backgroundColor: "#00a04f" }}
        desktop={desktop}
      >
        {rightScreen}
      </Fragment>
    </div>
  );
};

const stepsRouter: { [key: string]: React.ReactNode } = {
  start: (
    <SplitScreen
      height={true}
      leftScreen={<StartScreen />}
      rightScreen={
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <img src={logo}></img>
          </div>
        </div>
      }
    />
  ),
  startLoading: <StartLoading />,
  finish: <SurveyFinishScreen />,
  noProducts: <NoProductsScreen />,
  phoneSubmit: <PhoneSubmitScreen />,
  products: (
    <SplitScreen
      height={true}
      leftScreen={<ProductsScreen />}
      rightScreen={<SummaryBenefitsScreen />}
    />
  ),
  productsCart: <ProductsCart />,
  purchaseData: <PurchaseDataScreen />,
  purchasePayment: <PurchasePaymentScreen />,
  purchaseDelivery: <PurchaseDeliveryScreen />,
  purchaseInfo: <PurchaseInfoScreen />,
  paymentFailed: <PurchasePaymentFailedScreen />,
  paymentSuccess: <PurchasePaymentSuccessScreen />,
  fakePayment: <FakePayment />,
  paymentWait: <PaymentWait />,
};

export const Router = new Proxy(stepsRouter, {
  get: (target, p) =>
    p in target ? (
      target[p as string]
    ) : (
      <SplitScreen
        height={true}
        scroll={false}
        leftScreen={<StepScreen />}
        rightScreen={<SummaryBenefitsScreen key={"summaryScreenBenefit"} />}
      />
    ),
});

export interface ISplitScreen {
  leftScreen: React.ReactNode | any;
  rightScreen: React.ReactNode | any;
  step?: IStep | undefined;
  scroll?: boolean;
  height?: boolean;
}
