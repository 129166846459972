import React from "react";
import {
  BenefitsInfo,
  ScreenWrapper,
  SummaryBenefits,
} from "../../components/Screens";
import { AttachMoney, AvTimer, Eco } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  getEcoBenefits,
  getFundsBenefits,
  getSummaryBenefits,
  getTimeBenefits,
} from "../../reducers/benefits/selectors";
import content from "../../content";
import { getCurrentStep, getStepAnswers } from "../../reducers/steps/selectors";
import styled from "styled-components";

const Label = styled.div`
  color: white;
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 50px;
`;

function SummaryBenefitsScreen() {
  const summaryBenefits = useSelector(getSummaryBenefits);

  const ecoBenefits = useSelector(getEcoBenefits);
  const fundsBenefits = useSelector(getFundsBenefits);
  const timeBenefits = useSelector(getTimeBenefits);

  return (
    <ScreenWrapper style={{ height: "unset" }}>
      {summaryBenefits.length === 0 ? (
        <BenefitsInfo />
      ) : (
        <>
          <Label>{content.getValue("benefits_real_pros")}</Label>
          <SummaryBenefits
            key={"summaryBenefits1"}
            icon={<AttachMoney />}
            label={content.getValue("benefits_finances")}
            list={fundsBenefits}
          />
          <SummaryBenefits
            key={"summaryBenefits2"}
            icon={<AvTimer />}
            label={content.getValue("benefits_time")}
            list={timeBenefits}
          />
          <SummaryBenefits
            key={"summaryBenefits3"}
            icon={<Eco />}
            label={content.getValue("benefits_env")}
            list={ecoBenefits}
          />
        </>
      )}
    </ScreenWrapper>
  );
}

export default SummaryBenefitsScreen;
