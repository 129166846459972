import React from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

export const FADE_TIMEOUT = 400;

export default class Fade extends React.Component<CSSTransitionProps> {
  render() {
    return <CSSTransition {...this.props} timeout={FADE_TIMEOUT} />;
  }
}
