import { AnyAction } from "redux";
import initialStore from "./initialStore";
import { StepsActions } from "./actionsNames";
import { IBenefit } from "../benefits";
import { IVars } from "../../saga/fetchVars";

export default (store: IStepsStore = initialStore, action: AnyAction) => {
  switch (action.type) {
    case StepsActions.NextStep:
      return {
        ...store,
        previousSteps: [...store.previousSteps, store.currentStep],
        currentStep: action.step,
      };
    case StepsActions.Reset:
      return {
        ...store,
        previousSteps: [],
        stepAnswers: [],
        values: [],
        customValue: 0,
        repeat: 1,
        units: 2,
        period: 4,
      };
    case StepsActions.InsertContent:
      return {
        ...store,
        loading: false,
        content: action.content,
      };
    case StepsActions.SetState:
      return {
        ...store,
        ...action.stepState,
      };
    case StepsActions.PreviousStep:
      let step = store.previousSteps.pop();
      const currentStep = store.currentStep;

      if (step === "products" && currentStep === "products") {
        let step1 = store.previousSteps.pop();
        while (step1 === "products" || step1 === "noProducts") {
          step1 = store.previousSteps.pop();
        }

        return {
          ...store,
          currentStep: store.previousSteps.pop(),
          stepAnswers: [
            ...store.stepAnswers.filter((s) => s.step !== action.step),
          ],
          values: [...store.values.filter((v) => v.step !== action.step)],
        };
      }

      if (step === "products" && currentStep === "noProducts") {
        let step1 = store.previousSteps.pop();
        while (step1 === "noProducts" || step1 === "products") {
          step1 = store.previousSteps.pop();
        }

        return {
          ...store,
          currentStep: store.previousSteps.pop(),
          stepAnswers: [
            ...store.stepAnswers.filter((s) => s.step !== action.step),
          ],
          values: [...store.values.filter((v) => v.step !== action.step)],
        };
      }

      while (step === "finish" || step === "noProducts") {
        step = store.previousSteps.pop();
      }

      return {
        ...store,
        currentStep: step,
        stepAnswers: [
          ...store.stepAnswers.filter((s) => s.step !== action.step),
        ],
        values: [...store.values.filter((v) => v.step !== action.step)],
      };
    case StepsActions.SetAnswer:
      return {
        ...store,
        stepAnswers: [
          ...store.stepAnswers.filter((s) => s.step !== action.answer.step),
          action.answer,
        ],
        values: [
          ...store.values.filter((v) => v.step !== action.answer.step),
          { values: action.answer.values, step: action.answer.step },
        ],
      };
    case StepsActions.InsertSteps:
      return {
        ...store,
        steps: action.steps,
      };
    case StepsActions.SetCustom:
      return {
        ...store,
        customValue: action.v,
      };
    case StepsActions.SetPeriod:
      return {
        ...store,
        period: action.v,
      };
    case StepsActions.SetUnits:
      return {
        ...store,
        units: action.v,
      };
    case StepsActions.NextWaterPoint: {
      return {
        ...store,
        repeat: store.repeat + 1,
        currentStep: "7",
        stepAnswers: store.stepAnswers.filter(
          (s) => s.step !== "5" && s.step !== "6" && s.step !== "7"
        ),
        values: store.values.filter(
          (s) => s.step !== "5" && s.step !== "6" && s.step !== "7"
        ),
      };
    }
    case StepsActions.RemoveAnswer:
      return {
        ...store,
        stepAnswers: [
          ...store.stepAnswers.filter((s) => s.step !== action.step),
        ],
        values: [...store.values.filter((v) => v.step !== action.step)],
      };
    case StepsActions.InsertVars:
      return {
        ...store,
        vars: action.vars,
      };
    default:
      return store;
  }
};

export enum QuestionType {
  YesNo,
  Choice,
  Answer,
}

export interface IAnswers {
  id: number;
  name: string;
  content: string;
  nextStep: string;
  overrideNextStep: string;
  benefits: IBenefit[];
  features: IFeature[];
  values: IValue[];
  vars: IVars[];
}

export interface IValue {
  name: string;
  value: string;
}

export interface IFeature {
  id: number;
  deleted: boolean;
}

export interface IStep {
  id: number;
  step: string;
  nextStep: string;
  type: QuestionType;
  question: string;
  subQuestion?: string;
  ansewers: IAnswers[];
  progression: number;
  info: string;
}

export interface IStepsStore {
  currentStep: string;
  previousSteps: string[];
  content: IContent[];
  loading: boolean;
  stepAnswers: IStepAnswers[];
  values: Array<{ values: IValue[]; step: string }>;
  steps: IStep[];
  customValue: number;
  units: number;
  repeat: number;
  period: number;
}

export interface IContent {
  id: number;
  slug: string;
  value: string;
  lang: string;
}

export interface IStepAnswers {
  id: number;
  step: string;
  answer: string;
  answerVerbose: string;
  value?: string;
  next: string;
  overrideNext: string;
  benefits: number[];
  features: number[];
  values: IValue[];
  customFilters: any;
  repeat: number;
}
