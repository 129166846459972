import * as Effects from "redux-saga/effects";
import { BenefitsAction } from "../reducers/benefits/actionsNames";
import { IBenefit } from "../reducers/benefits";
import { StepsActions } from "../reducers/steps/actionsNames";

function* fetchVars() {
  const response = yield fetch(process.env.REACT_APP_API_URL + "/vars/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  let vars: IVars[] = yield response.json();

  yield Effects.put({
    type: StepsActions.InsertVars,
    vars: vars,
  });
}

export default function* () {
  yield Effects.takeLatest(StepsActions.FetchVars, fetchVars);
}

export interface IVars {
  id: number;
  name: string;
  value: string;
}
