import React, { useEffect, useState } from "react";
import { ScreenWrapper, StepControls } from "../../components/Screens";
import { Divider } from "@material-ui/core";
import styled from "styled-components";
import Checkbox from "../../components/Steps/Checkbox";
import { Column } from "./PurchaseForm";
import { PurchaseInfoAlert } from "../../components/Purchase";
import { useDispatch, useSelector } from "react-redux";
import setPaymentMethod from "../../actions/products/setPaymentMethod";
import { getPaymentMethod } from "../../reducers/products/selectors";
import content from "../../content";

const Header = styled.div`
  font-size: 2.5em;
  margin-bottom: 40px;
  color: rgb(85, 85, 85);
`;

function PurchasePayment({}: IPurchasePayment) {
  const [value, setValue] = useState("full");
  const dispatch = useDispatch();

  const paymentMethod = useSelector(getPaymentMethod);

  useEffect(() => {
    if (paymentMethod) {
      setValue(paymentMethod);
    }
  }, [paymentMethod]);

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name);
  };

  const onClick = () => {
    dispatch(setPaymentMethod(value));
  };

  return (
    <ScreenWrapper backgroundColor={"white"}>
      <Divider style={{ width: "15%", margin: "40px 0px" }} />
      <Header>{content.getValue("payment_select_header")}</Header>

      <Column>
        <Checkbox
          name={"partial"}
          fontSize={'1em'}
          checked={value === "partial"}
          label={content.getValue("payment_partial")}
          input={{} as any}
          meta={{} as any}
          onChange={onChange}
        />
        <Checkbox
          checked={value === "full"}
          fontSize={'1em'}
          onChange={onChange}
          name={"full"}
          label={content.getValue("payment_full")}
          input={{} as any}
          meta={{} as any}
        />
      </Column>
      <PurchaseInfoAlert />
      <StepControls
        next={"purchaseDelivery"}
        thisStep={"purchasePayment"}
        buttonLabel={
          value === "partial"
            ? content.getValue("payment_partial_button")
            : content.getValue("payment_full_button")
        }
        onNext={onClick}
      />
    </ScreenWrapper>
  );
}

export interface IPurchasePayment {}

export default PurchasePayment;
