import { State } from "../rootReducer";
import { createSelector } from "reselect";
import { BenefitType } from "../../steps";
import { IBenefit } from "./index";

export const getSummaryBenefits = (state: State) => state.benefits.summary;
export const getBenefits = (state: State) => state.benefits.benefits;
export const getStepBenefits = (state: State) => state.benefits.stepBenefits;

export const getBenefitsMapped = createSelector(
  [getBenefits, getSummaryBenefits],
  (benefits, summary) =>
    summary.map((s) => benefits.find((b: IBenefit) => b.id === s))
);

export const getEcoBenefits = createSelector(
  [getBenefits, getSummaryBenefits],
  (benefits, summary) =>
    summary
      .map((s) => benefits.find((b: IBenefit) => b.id === s))
      .filter((b) => b && b.type === BenefitType.Eco)
);

export const getFundsBenefits = createSelector(
  [getBenefits, getSummaryBenefits],
  (benefits, summary) =>
    summary
      .map((s) => benefits.find((b: IBenefit) => b.id === s))
      .filter((b) => b && b.type === BenefitType.Funds)
);

export const getTimeBenefits = createSelector(
  [getBenefits, getSummaryBenefits],
  (benefits, summary) =>
    summary
      .map((s) => benefits.find((b: IBenefit) => b.id === s))
      .filter((b) => b && b.type === BenefitType.Time)
);
