import * as Effects from "redux-saga/effects";
import { ProductsActions } from "../reducers/products/ActionsNames";
import { IDelivery } from "../reducers/products";

function* fetchDelivery() {
  const response = yield fetch(process.env.REACT_APP_API_URL + "/carrier/", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  let delivery: IDelivery[] = yield response.json();

  yield Effects.put({
    type: ProductsActions.InsertDelivery,
    delivery: delivery
      .filter((d) => d.active && (d as any).activeShop && !d.deleted)
      .sort((a, b) => Number(a.position) - Number(b.position)),
  });
}

export default function* () {
  yield Effects.takeLatest(ProductsActions.FetchDelivery, fetchDelivery);
}
