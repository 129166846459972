export enum ProductOfferType {
  Min,
  Optimal,
  Best,
}

export interface IProduct {
  id: number;
  offerType: ProductOfferType;
  name: string;
  image: string;
  descriptionList: string[];
  price: number;
  quantity: number;
  quantityMultiplicator: number;
  minQuantity: number;
  compatibility: number;
  features: any;
  state: any;
  prestaId: number;
}