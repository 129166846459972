import React from "react";
import { Button, ButtonProps, Tooltip } from "@material-ui/core";
import styled from "styled-components";

const StyledButton = styled(Button)<ButtonProps>`
 & > span > div > svg {
 transition: margin-left 0.2s ease-out;
 }
&:hover {
box-shadow: ${({disabled}) => disabled? 'unset': 'inset 0 0 6px #00000080'};
 & > span > div > svg {
  margin-left: 15px !important;
 }
}
`;

function RoundButton(props: ButtonProps & IButtonProps) {
  return props.disabled && props.disabledLabel? (
      <Tooltip enterDelay={500} placement={'top'} title={props.disabledLabel}>
    <StyledButton
      variant={"outlined"}
      {...props}
      style={{
        ...{
          borderRadius: "5px",
          fontWeight: 400,
            border: 'none',
          textTransform: "none",
          fontSize: "1.2em",
          backgroundColor: props.disabled
            ? ""
            : props.customBackgroundColor
            ? props.customBackgroundColor
            : "#ffbd00",
            pointerEvents: 'all',
            cursor: props.disabled? 'not-allowed':'pointer'
        },
        color: "black",
        ...props.style,
        ...(props.customColor
          ? { color: props.customColor, borderColor: props.customColor }
          : {}),
      }}
    />
      </Tooltip>
  ):(<StyledButton
      variant={"outlined"}
      {...props}
      style={{
          ...{
              borderRadius: "5px",
              fontWeight: 400,
              border: 'none',
              textTransform: "none",
              fontSize: "1.2em",
              backgroundColor: props.disabled
                  ? ""
                  : props.customBackgroundColor
                      ? props.customBackgroundColor
                      : "#ffbd00",
              pointerEvents: 'all',
              cursor: props.disabled? 'not-allowed':'pointer'
          },
          color: "black",
          ...props.style,
          ...(props.customColor
              ? { color: props.customColor, borderColor: props.customColor }
              : {}),
      }}
  />);
}

export default RoundButton;
export interface IButtonProps {
  customColor?: string;
  customBackgroundColor?: string;
  disabledLabel?: string
}
