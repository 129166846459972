import React from "react";
import styled from "styled-components";

const InfoRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 1.8em;
  margin-bottom: 15px;
`;

const Row = styled.div`
  color: rgb(85, 85, 85);
  margin-bottom: 6px;
  font-size: 1em;
`;

function InfoRow({ header, rows }: IInfoRow) {
  return (
    <InfoRowWrapper>
      <Header>{header}</Header>
      {rows.map((r) => (r && r.trim() ? <Row>{r}</Row> : <></>))}
    </InfoRowWrapper>
  );
}

export interface IInfoRow {
  header: string;
  rows: string[];
}

export default InfoRow;
