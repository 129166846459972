import React from "react";
import { Drawer } from "@material-ui/core";
import { IDrawer } from "../Screens/InfoDrawer";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import UnderlineButton from "../Screens/UnderlineButton";
import { ChevronRight } from "@material-ui/icons";
import { IProduct } from "../../products";
import content from "../../content";

const DrawerWrapper = styled.div`
  padding: 5%;
  width: 35vw;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const Label = styled.div`
  font-size: 1.5vw;
  font-weight: 600;
`;

function ProductDrawer({
  open,
  onClose,
  product,
}: IDrawer & { product: IProduct }) {
  return (
    <Drawer
      ModalProps={{ BackdropProps: { invisible: true } }}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <Scrollbars
        autoHeight={true}
        autoHeightMax={"100%"}
        autoHeightMin={"100%"}
      >
        <DrawerWrapper>
          <Header>
            <Label>{product.name}</Label>
            <UnderlineButton onClick={onClose}>
              {content.getValue("products_more_info_collapse")} <ChevronRight />
            </UnderlineButton>
          </Header>
        </DrawerWrapper>
      </Scrollbars>
    </Drawer>
  );
}

export default ProductDrawer;
