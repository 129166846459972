import React from "react";
import styled from "styled-components";

export const StyledInput = styled.input<{error?: string}>`
  height: 50px;
  box-sizing: border-box;
  background-color: white;
  border: ${(props:any) => Boolean(props.error) && Boolean(props.touched)?  '1px solid darkred':'1px solid #dcdcdc'};
  border-radius: 5px;
  width: 100%;
  padding: 0px 10px;
  color: black;
  margin-bottom: 20px;
  &:focus {
    border-color: #ffbd00;
  }
`;

function Input({ placeholder, input, meta, value, ...rest }: IInput) {
  return (
    <StyledInput
      {...rest}
      value={value? Number(value) <= 0? 0:value:value }
      {...input}
      {...meta}
      placeholder={placeholder}
    />
  );
}

export default Input;
export interface IInput extends React.HtmlHTMLAttributes<HTMLInputElement> {
  value?: string;
  input?: any;
  meta?: any;
  min?: number
  type?: string
}
