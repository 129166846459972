import React, {useEffect, useState} from 'react'
import {IStep} from "../../../containers/Steps";
import {IAnswers} from "../../../steps";
import {FormControlLabel, RadioGroup} from "../index";
import {Radio} from "@material-ui/core";
import StepInfoAssociation from "../StepInfoAssociation";
import {useSelector} from "react-redux";
import {getStepAnswers} from "../../../reducers/steps/selectors";

function CustomStep6({step, onChange, value}: ICustomStep6) {
 const stepsAnswers = useSelector(getStepAnswers)
 const [twin, setTwin] = useState(false)
 const [roof, setRoof] = useState(false)
 const [flat, setHouse] = useState(false)

 useEffect(() => {
  setTwin(!!stepsAnswers.find(s => s.answer === "twinHouse"))
  setHouse(!!stepsAnswers.find(s => s.answer === "flat"))
 },[stepsAnswers])


 function change(answer: IAnswers) {
  return function () {
   setRoof(answer.name === "roof" || answer.name === "freeSpace")
   onChange(answer);
  };
 }

 return (
   <>
    <F value={value} step={step} change={change}/>
    {(roof && (twin || flat) ) && <StepInfoAssociation/>}
    </>
 );
};

function F({value, step, change}:any) {

 return (<RadioGroup value={value} name={step.step}>
  {step.answers.map((a:any, i:number) => (
    <FormControlLabel
      onChange={change(a)}
      key={a.name}
      value={a.name}
      control={<Radio />}
      label={a.content}
    >
     {process.env.REACT_APP_DEBUG && <div style={{marginTop: '-20px', marginBottom: '20px'}}>{step.ansewers[i].features.map((f: any) => <p>{f.nameVerbose} {f.valueVerbose}</p>)}</div>}
    </FormControlLabel>))}
 </RadioGroup>)
}

export interface ICustomStep6 extends IStep {
 value?: string;
}

export default CustomStep6