import styled from "styled-components";

const ScreenWrapper = styled.div<IScreenWrapper>`
  background-color: ${(props) => props.backgroundColor? props.backgroundColor:'inherit'};
  padding: 80px 10%;
  width: 100%;
  height: 100%;
   @media (max-width: 800px) {
      height: ${({height}) => height? 'unset': '100%'};
      min-height: 100%;
  }
  box-sizing: border-box;
  color: black;
`;

export default ScreenWrapper;
export interface IScreenWrapper {
  backgroundColor?: string;
  height?: boolean
}
